import { Container } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


export const CustomContainer = (props: any) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xl'));
    return (
        <Container maxWidth={"lg"} className={matches ? "" : "px-0"}>
            {props.children}
        </Container>
    );
};