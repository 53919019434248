/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { AdminService } from '../../services/services';
import './DocumentUpload.scss';
import { CustomContainer } from '../../components/CustomContainer/CustomContainer';
import { Stack, Grid, Button, Avatar, List, Typography, IconButton, Chip, Modal, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert } from '@mui/material';
import { green, indigo, red } from '@mui/material/colors';
import { PiFolderOpenBold, PiPlus } from 'react-icons/pi';
import colors from '../../colors/colors';
import { FaDownload, FaFileCsv, FaFileImage, FaFilePdf, FaTrash } from 'react-icons/fa';
import moment from 'moment';
import { BsFiletypeXlsx, BsFiletypeDoc } from 'react-icons/bs';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import { FaCheckToSlot } from 'react-icons/fa6';
import Loading from '../../components/Loading/Loading';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview,FilePondPluginFileValidateType)

function DocumentUpload() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const allowed_file_type =[".pdf",".xlsx",".csv",".doc",".docx",".jpeg",".jpg",".png"];

    const operateur_id = queryParams.get('operateur_id');
    const member_id = queryParams.get('member_id');
    const devis_id = queryParams.get('devis_id');
    const [erreur, setErreur] = useState<string|null>("");
    const [erreurUpload, setErreurUpload] = useState<string|null>("");
    const [dataResult, setDataResult] = useState<any>();

    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [idDocumentDeleted, setIdDocumentDeleted] = useState<number|null>();
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setErreurUpload('');
    };
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingUpload, setLoadingUpload] = useState<boolean>(false);

    const [typeDirectorySelected,setTypeDirectorySelected] = useState('');
    const [typeDocumentIDSelected,setTypeDocumentIDSelected] = useState('');
    const [files, setFiles] = useState<any[]>([]);
    
    const [lstDocuments, setLstDocuments] = useState<any>([]);


    useEffect(() => {
        if (queryParams.get('prenom') && queryParams.get('email')) {
            getInfoDevis();
        }
    }, []);


    const uploadFile = async ()  =>{
        /**
         * DESC : convertir les fichiers en base64 et puis upload
         */
        // Vérification si les nombres de document ne dépasse la limite
        if(checkLargeFiles()) return;
        setErreurUpload('');
        
        if(!['tableau_amortissement','autre'].includes(typeDirectorySelected) && files.length>1){
            setErreurUpload(`Le type de document ne doit contenir qu'un seul fichier!`);
            return;
        }

        const uploadedFiles:File[] = files.map((fileItem:any) => fileItem.file);
        const base64Files = await convertFilesToBase64(uploadedFiles);
        
        const dataUpload:any[] = [];
        const repertoire = `${member_id}/project/${devis_id}/documents/${typeDirectorySelected}`;
        const typeDocumentSelected = dataResult?.documents_type.find((item:any)=>item.directory_name===typeDirectorySelected);
        new Promise((resolve)=>{
            base64Files.forEach((file,index)=>{
                dataUpload.push({
                    operateur_id : operateur_id,
                    member_id : member_id,
                    projet_id : devis_id,
                    type_projet : 'emprunteur',
                    type_document : typeDirectorySelected,
                    file : file.content,
                    filename : file.filename,
                    file_size : file.size,
                    repertoire : repertoire,
                    extension : `.${file.filename.split('.').pop()?.toLowerCase()}`,
                    date_creation : moment().format('YYYY-MM-DD HH:mm'),
                    type_document_libelle : typeDocumentSelected.libelle,
                    origine_upload : 'client',
                    is_read : false
                });

                if(index+1===base64Files.length){
                    resolve(dataUpload);
                }
            });
        }).then(()=>{
            // Enregistrement des documents
            saveDocuments(dataUpload);
        });
    }

    const convertFilesToBase64 = (uploadedFiles: File[]) => {
        /**
         * DESC : convert multiple file to base64
         */
        const fileReaders: Promise<{ filename: string, size:number, content: string }>[] = uploadedFiles.map(file => {
          return fileToBase64(file);
        });  
        return Promise.all(fileReaders);
    }

    const fileToBase64 = (file: File)=>{
        /**
         * DESC : convert file to base64
         */
        return new Promise<{ filename: string, size:number, content: string }>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve({ filename: file.name, size:file.size, content: reader.result as string });
          reader.onerror = error => reject(error);
          reader.readAsDataURL(file);
        });
    }

    const getInfoDevis = async () => {
        /**
         * DESC : Recupération des informations devis et documents
         */
        setErreur('');
        setLoading(true);
        checkCredentials().then((data: any) => {
            if (data['erreur']) {
                setErreur(data['erreur']);
                navigate(`/document/?${queryParams.toString()}`);
            } else {
                if(data?.success){
                    setDataResult(data);
                    setLstDocuments(data.documents);
                    setErreur('');
                }
            }
        }).catch(() => {
            setErreur('Une erreur s\'est produite');
            navigate(`/document/?${queryParams.toString()}`);
        }).finally(()=>{
            setLoading(false);
        });
    }

    const checkCredentials = async (isVerification:boolean=false) => {
        /**
         * Vérification credentials
         */
        const dataCredentials:any = {
            operateur_id : queryParams.get('operateur_id'),
            member_id : queryParams.get('member_id'),
            devis_id : queryParams.get('devis_id'),
            nom : queryParams.get('nom'),
            prenom : queryParams.get('prenom'),
            email : queryParams.get('email'),
            key : queryParams.get('key'),
        }

        if(isVerification){
            dataCredentials['verification'] = true;
        }

        return await AdminService.api_check_member_credentials(dataCredentials);
    };

    const saveDocuments = async (fileUploaded:any) => {
        /**
         * Enregistrement des documents
         */
        setLoadingUpload(true);
        // check document avant sauvegarde
        checkCredentials(true).then(async(data: any) => {
            if (data['erreur']) {
                setErreur(data['erreur']);
                navigate(`/document/?${queryParams.toString()}`);
            } else {
                // On upload les fichier si on a success true 
                if(data?.success){ 
                    // Sauvegarder les documents                               
                    await AdminService.api_save_documents({operateur_id:operateur_id, documents:fileUploaded}).then((dataResultUpload: any) => {
                        if (dataResultUpload['erreur']) {
                            setErreurUpload(dataResultUpload['erreur']);
                        } else {
                            setTypeDirectorySelected('');
                            setTypeDocumentIDSelected('');
                            window.location.reload();
                            
                            // Fermer le modal
                            handleClose();
                        }
                    }).catch(() => {
                        setErreur('Une erreur s\'est produite');
                    });
                }
            }
        }).catch(() => {
            setErreur('Une erreur s\'est produite');
        }).finally(()=>{
            setLoadingUpload(false);
        });
    };

    const downloadDocument = async (documentID:number) =>{
        /**
         * DESC : télécharger un document
         */
        setLoadingUpload(true);

        // Vérification credentials
        checkCredentials(true).then(async(data: any) => {
            if (data['erreur']) {
                setErreur(data['erreur']);
                navigate(`/document/?${queryParams.toString()}`);
            } else {
                // On télécharge le fichier si on a success true
                if(data?.success){
                    await AdminService.api_download_doc(documentID,queryParams.get('operateur_id') as string).then(async(data: any) => {
                        if (data['erreur']) {
                            setErreur(data['erreur']);
                        } else {
                            const dPdf = base64ToArrayBuffer(data['content']);
                            const blob = new Blob([dPdf], { type: getContentType(data['filename']) });
                            const nav = (window.navigator as any);
                            if (nav.msSaveOrOpenBlob) {
                                nav.msSaveOrOpenBlob(blob);
                            }
                            
                            const fileUrl = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            document.body.appendChild(link); // required in FF, optional for Chrome
                            link.href = fileUrl;
                            link.download = data['filename'];
                            link.click();
                        }
                    }).catch(() => {
                        setErreur('Une erreur s\'est produite');
                    }).finally(()=>{
                        setLoadingUpload(false);
                    });
                }
            }
        }).catch(() => {
            setErreur('Une erreur s\'est produite');
        })
    }

    const confirmDialog = (documentID:number) =>{
        handleOpenDialog();
        setIdDocumentDeleted(documentID);
    }

    const deleteDocument = async () =>{
        /**
         * DESC : Supprimer un document
         */
        handleCloseDialog();
        setLoadingUpload(true);

        // Vérification credentials
        checkCredentials(true).then(async(data: any) => {
            if (data['erreur']) {
                setErreur(data['erreur']);
                navigate(`/document/?${queryParams.toString()}`);
            } else {
                // On supprime le fichier si on a success true
                if(data?.success){              
                    await AdminService.api_delete_doc(idDocumentDeleted as number,queryParams.get('operateur_id') as string).then((data: any) => {
                        if (data['erreur']) {
                            setErreur(data['erreur']);
                        } else {
                            setLstDocuments(lstDocuments.filter((doc:any)=>doc.id!==idDocumentDeleted));
                            setIdDocumentDeleted(null);
                        }
                    }).catch(() => {
                        setErreur('Une erreur s\'est produite');
                        setIdDocumentDeleted(null);
                    });
                }
            }
        }).catch(() => {
            setErreur('Une erreur s\'est produite');
        }).finally(()=>{
            setLoadingUpload(false);
        });
    }
    
    const formatDate = (inputDate: string): string => {
        if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputDate)) {
            return inputDate;
        }

        // Parse and format the date using moment
        const formattedDate: string = moment(inputDate).format('DD/MM/YYYY HH:mm');
        return formattedDate;
    }

    const base64ToArrayBuffer = (data:any) => {
        const binaryString = window.atob(data);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
          const ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        return bytes;
    }

    const getContentType = (filename: string) => {
        const extension = filename.split('.').pop()?.toLowerCase();
        switch (extension) {
          case 'pdf': return 'application/pdf';
          case 'xlsx': return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          case 'csv': return 'text/csv';
          case 'doc': return 'application/msword';
          case 'docx': return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          case 'jpeg':
          case 'jpg': return 'image/jpeg';
          case 'png': return 'image/png';
          default: return 'application/octet-stream';
        }
    }

    
  const checkLargeFiles = () => {
    /**
     * DESC : Vérification des fichiers supérieur à la taille défini
     */    
    const maxSizeInMB = 25;
    const totalSizeInBytes  = files.reduce((total, item) => total + item.file.size / 1024 / 1024, 0);
    
    if (totalSizeInBytes > 25) {
        setErreurUpload(`Vos fichiers(${totalSizeInBytes.toFixed(2)} Mo) dépassent les ${maxSizeInMB} Mo.`);
        return true;
    } else {
        setErreurUpload('');
        return false;
    }
  }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        boxShadow: 24,
    };

    return (
        <>
            <CustomContainer>
                <Stack  pt={18} pb={10}>
                    <Grid container>
                        <Grid item md={2} xs={12}>
                            <Button style={{backgroundColor:indigo[700],textTransform:'none',cursor:'default'}} fullWidth={true} variant="contained" startIcon={<PiFolderOpenBold />} size='large'>Mes documents</Button>
                        </Grid>

                        <Grid item md={10} xs={12} px={4}>
                            <h3>Bienvenue dans l'espace de depôts de documents !</h3>
                            { erreur !=='' &&  (<Alert sx={{marginY:2}} severity="error">{erreur}</Alert>)}
                                                                                    
                            {((): any => {
                                if (loading) {
                                    return (
                                        <Box><Loading /></Box>
                                    )
                                }else{ return(
                                    <>
                                        <Typography component={'h3'} style={{fontSize:25,color:colors.blue}}>Votre projet emprunteur</Typography>
                                        <Grid container>
                                            <Grid item md={4} xs={12} sx={{px:{md:4},mb:{xs:2}}}>
                                                <p style={{fontSize:15,marginBottom:25}} className='p-bold'>Formule recommandée</p>
                                                <p style={{display:'inline'}} className={`produit produit-${dataResult?.id_produit}`}>{dataResult?.produit}</p>
                                            </Grid>
                                            <Grid item md={4} xs={12}>
                                                <p style={{fontSize:15}} className='p-bold'>Adhérent(s)</p>
                                                {
                                                    dataResult?.adherents.map((adherent:any,index:number)=>(
                                                        <p key={index} style={{fontSize:15}}>{adherent.fullname} {adherent.date_naissance} {adherent.is_principal ? '(principal)':''}</p>
                                                    ))
                                                }
                                            </Grid>
                                            <Grid item md={4} xs={12}>
                                                <p style={{fontSize:15}} className='p-bold'>Cotisation mensuelle moyenne</p>
                                                <p style={{fontSize:25}}>{dataResult?.prime} €</p>
                                            </Grid>
                                        </Grid>
                                                    
                                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                            <p style={{color:colors.blue}} className='p-bold'>Liste</p>
                                            <Button style={{backgroundColor:indigo[700],textTransform:'none',cursor:'default'}}  variant="contained" startIcon={<PiPlus />} size='small' onClick={()=>{handleOpen();setFiles([])}}>Ajouter</Button>
                                        </Stack>

                                        {((): any => {
                                            if (loadingUpload) {
                                                return (
                                                    <Box><Loading /></Box>
                                                )
                                            }else{ return(
                                                <Stack>
                                                    <List sx={{ width: '100%'}}>
                                                        {
                                                            lstDocuments.map((document:any,index:number) => (
                                                                <Grid container key={index} className='item-doc' px={2} my={2} py={2}>
                                                                    <Grid display={'flex'} item md={1} xs={2} px={2} sx={{px:{xs:1},alignItems:{md:'center'}}}>
                                                                        <Avatar sx={{backgroundColor:indigo[700]}}>
                                                                            { document.extension==='.pdf' && (<FaFilePdf />)}
                                                                            { document.extension==='.xlsx' && (<BsFiletypeXlsx />)}
                                                                            { document.extension==='.csv' && (<FaFileCsv />)}
                                                                            { document.extension==='.doc' && (<BsFiletypeDoc />)}
                                                                            { ['.jpg','.png','.jpeg'].includes(document.extension) && (<FaFileImage />)}
                                                                        </Avatar>
                                                                    </Grid>
                                                                    <Grid item md={11} xs={10}>
                                                                        <Grid container>
                                                                            <Grid display={'flex'} direction={'column'} justifyContent={'center'} item md={6} xs={12} px={4} sx={{px:{xs:1}}}>
                                                                                <Typography>
                                                                                    <span style={{fontWeight:'bold',fontFamily: "verdana",fontStyle: 'normal'}}>{document.filename}</span>
                                                                                </Typography>
                                                                                <Typography>
                                                                                    <span style={{display:'block'}}>
                                                                                        ajouté le {formatDate(document.date_creation)}
                                                                                    </span>
                                                                                </Typography>
                                                                            </Grid>

                                                                            <Grid item md={4} xs={12} px={2} sx={{py:{xs:2},px:{xs:1}}}>
                                                                                <Chip sx={{color:indigo[500]}} label={document.type_document_libelle} />
                                                                            </Grid>

                                                                            <Grid item md={2} xs={12} sx={{display:{md:'flex'}}} alignItems={'center'} justifyContent={'end'}>
                                                                                {
                                                                                    document.origine_upload==='client' && (<IconButton sx={{color: document?.is_read ? green['500']:'',cursor:'default'}} size='small' aria-label='Consulté' title={document?.is_read ? 'Consulté':'Non consulté'}>
                                                                                        <FaCheckToSlot />
                                                                                    </IconButton>)
                                                                                }

                                                                                <IconButton onClick={()=>downloadDocument(document.id)} size='small' sx={{color:indigo[500]}} aria-label='Télécharger' title='Télécharger'>
                                                                                    <FaDownload />
                                                                                </IconButton>
                                                                                
                                                                                {
                                                                                    document.origine_upload==='client' && !dataResult?.is_contrat && (<IconButton onClick={()=>confirmDialog(document.id)} size='small' style={{color:red[500]}} aria-label='Supprimer' title='Supprimer'>
                                                                                        <FaTrash />
                                                                                    </IconButton>)
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            ))
                                                        }
                                                    </List>
                                                </Stack>
                                            )}
                                        })()}
                                    </>
                                )}
                            })()}

                            
                            {/* Création de document  */}
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description">
                                <Box sx={style}>
                                    <Typography paddingX={4} paddingY={2} id="modal-modal-title" variant="h5" fontWeight={'bold'} component="h3">
                                        Ajout nouveau document
                                    </Typography>
                                    <hr />
                                    <Stack paddingX={4} paddingBottom={4}>
                                        <p className='p-bold' style={{fontSize:15}}>Choisir la catégorie : </p>
                                        <Stack direction={'row'} flexWrap={'wrap'} gap={1.5}>
                                            {
                                                dataResult?.documents_type.map((document_type:any,index:number)=>(
                                                    <Chip className='type-doc' onClick={()=>{setTypeDocumentIDSelected(document_type.id);setTypeDirectorySelected(document_type.directory_name)}} sx={document_type.id===typeDocumentIDSelected ? { color : 'white',backgroundColor:indigo[500]}:{ color : indigo[500]}} key={index} label={document_type.libelle} />
                                                ))
                                            }
                                        </Stack>

                                        { erreurUpload !=='' &&  (<Alert sx={{marginY:2}} severity="error">{erreurUpload}</Alert>)}
                                        
                                        <p className='p-bold' style={{fontSize:15}}>Ajouter ou glisser-déposer ici votre document : </p>
                                                
                                        <Stack className='list-documents-upload'>
                                            <FilePond
                                                acceptedFileTypes={['image/png', 'image/jpeg','application/pdf','text/csv','application/msword','application/octet-stream','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                                                fileValidateTypeLabelExpectedTypesMap={{ 'image/jpeg': '.jpeg', 'image/png': '.png','application/pdf':'.pdf','text/csv':'.csv','application/msword':'.doc','application/vnd.openxmlformats-officedocument.wordprocessingml.document':'.docx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':'.xlsx' }}
                                                labelFileTypeNotAllowed={`Seuls les fichiers de type ${allowed_file_type} sont autorisés`}
                                                files={files}
                                                onupdatefiles={setFiles}
                                                allowMultiple={['tableau_amortissement','autre'].includes(typeDirectorySelected) ? true : false}
                                                maxFiles={10}
                                                name="files"
                                                credits={false}
                                                labelIdle='<span class="filepond--label-action">Parcourir ( max 25 Mo )</span>'
                                            />
                                        </Stack>
                                        
                                        <Stack direction={'row'} justifyContent={'end'} paddingTop={2}>
                                            <Button style={{color:indigo[700],textTransform:'none'}}  variant="text" size='small' onClick={handleClose}>Annuler</Button>
                                            <Button className={files.length===0 || !typeDocumentIDSelected ? 'disabled-btn-valid':''} disabled={files.length===0 || !typeDocumentIDSelected} style={{backgroundColor:indigo[700],textTransform:'none',marginLeft:10}}  variant="contained" size='small' onClick={uploadFile}>Valider</Button>
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Modal>
                            {/* Création de document  */}
                            
                            {/* Confirmation de suppression de document */}
                            <Dialog open={openDialog} keepMounted onClose={handleClose}>
                                <DialogTitle>
                                    <p className="m-0 text-bold" style={{ fontSize: 25 }}>Confirmation de suppression</p>
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText component={"div"}>
                                        <p>Êtes-vous sûr de vouloir supprimer ce document?</p>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions sx={{paddingX:3,paddingY:2}}>
                                    <Button sx={{color:indigo[700]}} onClick={handleCloseDialog} variant="text">
                                        Non
                                    </Button>
                                    <Button style={{backgroundColor:red[500]}} variant="contained" onClick={()=>deleteDocument()}>
                                        Oui
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            {/* Confirmation de suppression de document */} 
                        </Grid>
                    </Grid>
                </Stack>
            </CustomContainer>
        </>
    )
}

export default DocumentUpload; 