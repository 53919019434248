import React from 'react';
import { useScrollTrigger } from '@mui/material';


export const ElevationScroll = (props: any) => {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        style: { backgroundColor: trigger ? "white" : "white" }
    });
};