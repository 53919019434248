import { Button, Grid, Stack, Box, TextField, createTheme, ThemeProvider, useTheme, useMediaQuery } from '@mui/material';
import { Container } from '@mui/system';
import phoneIcon from "../../assets/icons/phoneFill.svg";
import pinIcon from "../../assets/icons/pin.svg";
import facebookIcon from "../../assets/icons/facebook.svg";
import instagramIcon from "../../assets/icons/instagram.svg";
import linkedinIcon from "../../assets/icons/linkedin.svg";
import envelopeIcon from "../../assets/icons/envelope.svg";
import doodleArrow from "../../assets/doodles/doodle arrow.svg";
import colors from '../../colors/colors';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { CustomContainer } from '../CustomContainer/CustomContainer';
import Error from '../Error/Error';
import { AdminService } from '../../services/services';
import './ContactForm.scss';


const themeContact = createTheme({
    palette: {
        primary: {
            main: '#999'
        }
    }
});

const assurances = [
    { id: "emprunteur", title: "Assurance de prêt" },
    { id: "sante", title: "Assurance santé" },
    { id: "autres", title: "Autres" }
];

const phoneNumberRegex = new RegExp(`^(0|(00|\\+)33)[123456789][0-9]{8}$`);
const emailRegEx = new RegExp('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}');


export default function ContactForm() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const [data, setData] = useState<any>({
        assurance: "emprunteur", nom_prenom: "", email: "", telephone: "", message: ""
    });
    const [erreurMessage, setErreurMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    let erreur = `
        Une erreur inconnue s'est produite, 
        veuillez attendre un peu puis réessayer ou contactez nous à contact@julia-assurance.fr !
    `;

    const setContactData = (key: string, value: any) => {
        setData((prevState: any) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const sendDemand = async () => {
        setSuccessMessage(null);
        setErreurMessage(null);
        if (isDataValid()) {
            setErreurMessage(null);
            await AdminService.api_send_demand_rappel(data).then((result: any) => {
                if (result['erreur']) {
                    setErreurMessage(erreur);
                } else {
                    setSuccessMessage("Votre demande a été envoyée");
                    emptyFields();
                    setTimeout(() => {
                        setSuccessMessage(null);
                    }, 3000);
                }
            }).catch(() => {
                console.log("__error__send__rappel__");
                setErreurMessage(erreur);
            });
        }
        else {
            setErreurMessage('Oups il vous manque des informations !');
        }
    };

    const isDataValid = () => {
        const { assurance, nom_prenom, email, telephone, message } = data;
        if (!assurance || !nom_prenom || !email || !telephone || !message) {
            return false;
        }
        if (!phoneNumberRegex.test(telephone)) {
            return false;
        }
        if (!emailRegEx.test(email)) {
            return false;
        }
        return true;
    };

    const emptyFields = () => {
        const fields = ["nom_prenom", "email", "telephone", "message"]
        for (const field of fields) {
            setContactData(field, "");
        }
        setContactData("assurance", "emprunteur");
    };


    return (
        <Container id={"contact"} className={`px-0 ${!matches ? "contact" : ""}`} maxWidth={false}
            sx={{ pb: { md: 20, xs: 10 }, pt: { md: 0, xs: 10 }, bgcolor: { xs: colors.blue, md: "transparent" } }}
        >
            <CustomContainer>
                <Box p={{ md: 4, xs: 0 }} bgcolor={colors.blue} borderRadius={5}>
                    <Grid container alignItems={"start"}>
                        <Grid item md={6}>
                            <Stack direction={{ md: "row", xs: "column" }} alignItems={"start"}>
                                <h2 className='h2-white'>
                                    Contactez-nous pour une solution d'assurance sur mesure !
                                </h2>
                                <Box component="img" src={doodleArrow} alt="doodle arrow" width={{ xs: 35, md: 73 }}
                                    sx={{ m: { xs: "auto", md: "unset" } }}
                                />
                            </Stack>
                        </Grid>
                        <Grid container item md={6} justifyContent={"end"} display={{ xs: "none", md: "flex" }}>
                            <Stack direction={"column"} spacing={3}>
                                <Stack direction={"row"}>
                                    <Box component="img" src={envelopeIcon} alt="envelope icon" width={25} mr={1} />
                                    <p className={"p-white m-0"} style={{ fontSize: 18 }}>contact@julia-assurance.fr</p>
                                </Stack>
                                <Stack direction={"row"} >
                                    <Box component="img" src={phoneIcon} alt="phone icon" width={25} mr={1} />
                                    <p className={"p-white m-0"} style={{ fontSize: 18 }}>01 86 76 72 91</p>
                                </Stack>
                                <Stack direction={"row"} >
                                    <Box component="img" src={pinIcon} alt="pin icon" width={25} mr={1} />
                                    <p className={"p-white m-0"} style={{ fontSize: 18 }}>143 Boulevard René Cassin - Immeuble Nouvel’R - Bat C - CS 63278 - 06205 Nice CEDEX 3</p>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Box p={{ md: 4, xs: 0 }} borderRadius={5} bgcolor={{ md: "white", xs: colors.blue }} mt={3}>
                        {erreurMessage && (<Error error={erreurMessage} />)}
                        {successMessage && (
                            <Stack bgcolor={colors.green} py={1.5} px={1.5} borderRadius={0.5} my={2}>
                                <p className='m-0 century text-white align-center'>{successMessage}</p>
                            </Stack>
                        )}
                        <Grid container alignItems={"start"}>
                            <Grid item md={6}>
                                <Stack direction={"column"} gap={{md : 0, xs : 2}}>
                                    <p className={`${matches ? "h1-white" : ""}`}>Je suis intéressé(e) par... </p>
                                    <Box sx={{ display: "flex", flexWrap: "wrap" }} gap={1.5} mb={{ md: 0, xs: 2.5 }}>
                                        {assurances.map((as, index) => (
                                            <Button onClick={() => { setContactData("assurance", as.id) }} key={index} className={data["assurance"] === as.id ? "btn-contact" : "btn-contact-outlined"}>
                                                {as.title}
                                            </Button>
                                        ))}
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <ThemeProvider theme={themeContact}>
                                    <Box component="form" noValidate autoComplete="off">
                                        <Stack direction={"column"} spacing={2}>
                                            <TextField label="Nom et prénom" variant="standard" className='contact-input' onChange={(event: any) => setContactData("nom_prenom", event.target.value)}
                                                value={data["nom_prenom"]}
                                            />
                                            <TextField label="Adresse e-mail" variant="standard" className='contact-input' onChange={(event: any) => setContactData("email", event.target.value)}
                                                value={data["email"]}
                                            />
                                            <TextField label="Numéro de téléphone" variant="standard" className='contact-input' onChange={(event: any) => setContactData("telephone", event.target.value)}
                                                value={data["telephone"]}
                                            />
                                            <TextField label="Message" variant="standard" className='contact-input' onChange={(event: any) => setContactData("message", event.target.value)}
                                                value={data["message"]}
                                            />
                                        </Stack>
                                    </Box>
                                    <Grid container justifyContent={"center"} mt={6}>
                                        <Button onClick={() => sendDemand()} startIcon={<SendIcon />} className="btn-decouvrir-pink etre-rappelle" variant="contained" fullWidth={matches ?? false}>
                                            Demander à être rappelé
                                        </Button>
                                    </Grid>
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                    </Box>
                    <Stack direction={"column"} spacing={2} display={{ xs: "flex", md: "none" }} mt={6}>
                        <Stack direction={"row"}>
                            <Box component="img" src={envelopeIcon} alt="envelope icon" width={25} mr={1} />
                            <p className={"p-white m-0"} style={{ fontSize: 16 }}>contact@julia-assurance.fr</p>
                        </Stack>
                        <Stack direction={"row"} >
                            <Box component="img" src={phoneIcon} alt="phone icon" width={25} mr={1} />
                            <p className={"p-white m-0"} style={{ fontSize: 16 }}>01 86 76 72 91</p>
                        </Stack>
                        <Stack direction={"row"} >
                            <Box component="img" src={pinIcon} alt="pin icon" width={25} mr={1} />
                            <p className={"p-white m-0"} style={{ fontSize: 16 }}>143 Boulevard René Cassin - Immeuble Nouvel’R - Bat C - CS 63278 - 06205 Nice CEDEX 3</p>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} spacing={3} mt={3}>
                        <Box component="img" src={facebookIcon} alt="facebook icon" />
                        <Box component="img" src={instagramIcon} alt="instagram icon" />
                        <Box component="img" src={linkedinIcon} alt="linkedin icon" />
                    </Stack>
                </Box>
            </CustomContainer>
        </Container>
    );
};