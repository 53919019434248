/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation } from "react-router-dom";
import { CustomContainer } from "../../components/CustomContainer/CustomContainer";
import { useEffect, useState } from "react";
import ParcoursService from "../../services/services";
import { Button, Stack } from "@mui/material";
import moment from "moment";
import { IoWarning } from "react-icons/io5";
import { ImFilePdf } from "react-icons/im";
import "./Signature.scss";

interface CustomWindow extends Window {
    dataLayer: any[],
};


export default function Signature() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [signatureInfos, setSignatureInfos] = useState<any>();
    const [erreurMessage, setErreurMessage] = useState<any>("Erreur : devis non trouvé ou lien expiré !");


    useEffect(() => {
        if (queryParams.get('did') && queryParams.get('mid')) {
            ParcoursService.api_get_devis_signature_infos(queryParams.get('did') as string, queryParams.get('mid') as string).then((res) => {
                if (res) {
                    if (!res["ok"]) {
                        setErreurMessage(res["message"]);
                        window.scrollTo(0, 0);
                    } else {
                        setSignatureInfos(res['data']);
                    }
                }
            });
        };

        const appendGtmScript = () => {
            const scriptElement = document.createElement('script');
            scriptElement.id = 'gtm-script';
            scriptElement.innerHTML = `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-WMZCJLG5');
            `;

            const headElement = document.getElementById('head');
            if (headElement) {
                headElement.appendChild(scriptElement);
            }
        };
        appendGtmScript();

    }, []);


    const getBaseUrl = () => {
        return ParcoursService.baseUrl;
    };

    const isLinkExpired = () => {
        if (!signatureInfos) {
            return false;
        }
        return signatureInfos['is_link_expired'];
    };


    const formatDate = (inputDate: string): string => {
        if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputDate)) {
            return inputDate;
        }

        // Parse and format the date using moment
        const formattedDate: string = moment(inputDate).format('DD/MM/YYYY');
        return formattedDate;
    }

    const goToYouSign = () => {
        window.open(signatureInfos['url_yousign'], '_blank');
        // Google tag manager
        if (typeof window !== 'undefined') {
            const customWindow = window as unknown as CustomWindow;
            customWindow.dataLayer.push({
                'event': 'formSubmit.Success'
            });
        }
    }

    return (
        <div style={{ overflowX: "hidden" }}>
            <CustomContainer>
                {((): any => {
                    if ((isLinkExpired() || !signatureInfos)) {
                        return (
                            <Stack alignItems={"center"}>
                                <Stack bgcolor={"#f8d7da"} py={2.5} px={2} borderRadius={1} my={2} borderColor={"#f5c6cb"}>
                                    <small className='m-0 century align-center' style={{ color: "#7c2932", fontSize: 15.5, display: "flex", alignItems: "center" }}>
                                        <IoWarning size={22} /> {erreurMessage}
                                    </small>
                                </Stack>
                            </Stack>
                        )
                    }
                    if ((!isLinkExpired() && signatureInfos)) {
                        return (
                            <Stack width={"100%"} pb={4}>
                                <h2 className="text-pink" style={{ textTransform: "uppercase" }}>Adhésion électronique</h2>
                                <Stack width={"100%"} className="products-container">
                                    <Stack p={3}>
                                        {
                                            signatureInfos['devis_produits'].map((product: any) => (
                                                <div className="product">
                                                    <h4 className="nom-produit century m-0" style={{ fontSize: 25 }}>{product.nom_produit}</h4>
                                                    <Stack gap={1} mt={1}>
                                                        <p className="nom-formule m-0 century">Formule(s) recommandée(s) : {product.nom_formule}</p>

                                                        {product.is_devis_prevoyance && (
                                                            <p className="m-0 century">
                                                                <strong>Couverture prévoyance pour</strong> : {product.member_nom} {product.member_prenom}
                                                            </p>
                                                        )}

                                                        {product.is_devis_sante && (
                                                            <p className="m-0 century">
                                                                <strong>Couverture mutuelle santé pour</strong> : {product.member_nom} {product.member_prenom}
                                                            </p>
                                                        )}

                                                        <p className="m-0 century">
                                                            {product.conjoint && (
                                                                <span>Votre conjoint {product.conjoint.nom} {product.conjoint.prenom}</span>
                                                            )}

                                                            {product.nb_enfants > 0 && (
                                                                <span>
                                                                    {product.nb_enfants === 1 ? ' et votre enfant' : ` et vos ${product.nb_enfants} enfants`}
                                                                </span>
                                                            )}
                                                        </p>
                                                    </Stack>

                                                    <p className="date-effet century">Effet au {product.date_effet}</p>

                                                    {
                                                        product?.devis_garanties?.length > 0 && (
                                                            <ul className="garanties">
                                                                {product.devis_garanties.map((garantie: any, index: number) => (
                                                                    <li key={index}>
                                                                        <a
                                                                            href={getBaseUrl() + garantie.file_path}
                                                                            className="garantie"
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            <ImFilePdf size={40} />
                                                                            <span className="garantie-file century">{garantie.file_name}</span>
                                                                        </a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )
                                                    }
                                                </div>
                                            ))
                                        }
                                    </Stack>
                                </Stack>
                                <Stack width={"100%"}>
                                    <div className="row">
                                        {/* personnes concernées */}
                                        <div className="">
                                            <p>Cette demande d'adhésion concerne les signataires suivants :</p>
                                            <table className="subscribers">
                                                <thead>
                                                    <tr>
                                                        <td>Nom et prénom</td>
                                                        <td>Date de naissance</td>
                                                        <td>Régime social</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {signatureInfos['devis_subscribers'].map((subscriber: any, index: number) => (
                                                        <tr key={index}>
                                                            <td>{`${subscriber.nom} ${subscriber.prenom}`}</td>
                                                            <td> {formatDate(subscriber.date_naissance)}</td>
                                                            <td>{subscriber.regime_social}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        {/* garanties */}
                                        <div className="">
                                            <p className="garanties-info">
                                                Pour plus d'informations, nous vous invitons à consulter le tableau des garanties ou les conditions
                                                générales du contrat ci-dessus.
                                            </p>
                                        </div>

                                        {/* signature access */}
                                        <div className="text-center">
                                            {!signatureInfos['has_expired_devis'] ? (
                                                <Button className="btn-suivant" onClick={() => goToYouSign()}>
                                                    <span style={{ color: "white" }}>Accéder aux documents à signer</span>
                                                </Button>
                                            ) : (
                                                <Stack alignItems={"center"}>
                                                    <Stack bgcolor={"#f8d7da"} py={2.5} px={2} borderRadius={1} my={2} borderColor={"#f5c6cb"}>
                                                        <small className='m-0 century align-center' style={{ color: "#7c2932", fontSize: 15.5, display: "flex", alignItems: "center" }}>
                                                            <IoWarning size={22} /> Date d'effet expirée, veuillez nous recontacter pour continuer votre adhésion.
                                                        </small>
                                                    </Stack>
                                                </Stack>
                                            )}
                                        </div>

                                        {/* instructions */}
                                        <div className="mb-4">
                                            <ol>
                                                <li style={{ marginBottom: 10 }}>Accédez à la signature électronique en cliquant sur le bouton "Accéder aux documents à signer".</li>
                                                <li style={{ marginBottom: 10 }}>Une fois les documents affichés, signez-les à l'aide de la signature électronique.</li>
                                                <li style={{ marginBottom: 10 }}>
                                                    Vous recevrez immédiatement une copie de votre demande d'adhésion par email. Votre demande sera aussitôt
                                                    transmise à la compagnie pour traitement.
                                                </li>
                                                <li style={{ marginBottom: 10 }}>
                                                    Un certificat d'adhésion valant acceptation de votre demande et précisant la date d'effet de votre
                                                    adhésion ainsi qu'une carte de tiers payant vous seront adressés dans les meilleurs délais.
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </Stack>
                            </Stack >
                        )
                    }
                })()}
            </CustomContainer >
        </div >
    );
};