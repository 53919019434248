import { Stack } from '@mui/material';
import { RxCross1 } from "react-icons/rx";
import { useDispatch } from 'react-redux';
import { changeCurrentErrorMessage } from '../../redux/actions';
import { MdErrorOutline } from "react-icons/md";


export default function Error(props: any) {
    const dispatch = useDispatch();
    const error = props.error;

    return (
        <Stack bgcolor={"#f8d7da"} py={2.5} px={2} borderRadius={3} borderColor={"#f5c6cb"}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <small className='m-0 century-bold align-center'
                    style={{ color: "#7c2932", fontSize: 15, letterSpacing: 0.8, display: "flex", alignItems: "center" }}>
                    <MdErrorOutline size={24} style={{ marginRight: 4 }} /> {error}
                </small>
                <RxCross1 className='cursor-pointer' color='#7c2932' onClick={() => dispatch(changeCurrentErrorMessage(null))} />
            </Stack>
        </Stack>
    );
};