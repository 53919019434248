import { legacy_createStore as createStore, combineReducers } from 'redux';
import { parcoursReducer } from './reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';


const persistConfig = {
    key: 'root',
    blacklist: ["parcoursType"],
    storage
};

const rootReducer = combineReducers({
    step: parcoursReducer,
    stepShowed: parcoursReducer,
    currentErreurMessage: parcoursReducer,
    initData: parcoursReducer,
    info: parcoursReducer,
    stepsData: parcoursReducer,
    isButtonEnabled: parcoursReducer,
    loading: parcoursReducer,
    panier: parcoursReducer,
    panierGlobal: parcoursReducer,
    member: parcoursReducer,
    showPanier: parcoursReducer,
    canBeSigned: parcoursReducer,
    parcoursType: parcoursReducer,
    stepEmprunteur: parcoursReducer,
    currentSubstep: parcoursReducer,
    stepsDataEmprunteur: parcoursReducer,
    initDataEmprunteur: parcoursReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer);
export const persistor = persistStore(store);