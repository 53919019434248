/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, FormControl, TextField, ButtonGroup, Button, FormControlLabel, Checkbox } from '@mui/material';
import Label from '../../Label/Label';
import Error from '../../Error/Error';
import { changePanierGlobal, changeMember, changeCurrentErrorMessage, changeIsButtonEnabled, changeLoading, changePanier, changeShowPanier, changeStepsData } from '../../../redux/actions';
import ParcoursService from '../../../services/services';
import '../ParcoursSante.scss';
import { Link } from 'react-router-dom';


export default function SouscriptionSante() {
    const dispatch = useDispatch();
    const initData = useSelector((store: any) => store.initData.initData);
    const currentErreurMessage = useSelector((store: any) => store.currentErreurMessage.currentErreurMessage);
    const stepsData = useSelector((store: any) => store.stepsData.stepsData);
    const [data, setData] = useState<any>(stepsData["informations-paiement"]);
    const [lstJourPrelevement] = useState<any>(initData["lst_jour_prelevement"]);
    const product = "DevisSanteClient";


    useEffect(() => {
        window.scroll(0, 0);
        dispatch(changeIsButtonEnabled(false));
        return () => {
            dispatch(changeCurrentErrorMessage(currentErreurMessage ?? null))
            dispatch(changeIsButtonEnabled(false));
        }
    }, []);

    useEffect(() => {
        dispatch(changeStepsData({ ...stepsData, "informations-paiement": data }));
        isStepDataValid() ? dispatch(changeIsButtonEnabled(true)) : dispatch(changeIsButtonEnabled(false));
    }, [data]);


    const setStepData = (key: string, value: any) => {
        setData((prevState: any) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const getPanier = async () => {
        await ParcoursService.api_get_panier('', product).then((data: any) => {
            dispatch(changePanier(data['success']['devis_active']));
            dispatch(changePanierGlobal(data['success']));
            dispatch(changeMember(data['success']['global']));
            dispatch(changeShowPanier(true));
        }).catch(() => {
            dispatch(changeLoading(false));
        });
    };

    const isStepDataValid = () => {
        const { iban, bic, jour_prelevement, frequence_paiement, accept_conditions, accept_data } = data;
        if (!iban || !bic || !jour_prelevement || !frequence_paiement || !accept_conditions || !accept_data) {
            return false;
        }
        return true;
    };

    return (
        <Stack width={"100%"} spacing={3} pb={5}>
            {currentErreurMessage && (<Error error={currentErreurMessage} />)}
            <h3 className='century-bold m-0'>Vos informations bancaires</h3>
            <Stack width={"100%"} >
                <Stack bgcolor={"#eceeff"} py={3} px={1.5} textAlign={"center"}>
                    <p className='m-0'>Votre panier</p>
                    {
                        initData['panier']['devis_active']?.map((d: any, index: number) => (
                            <p className='asar' style={{ marginTop: 20, marginBottom: 0, fontSize: 20 }} key={index}>
                                {d["nom_produit"]} {d["nom_formule"]} à {d["prime_mensuel"]}€/mois
                            </p>
                        ))
                    }
                    <small className='cursor-pointer' style={{ marginTop: 20 }} onClick={() => getPanier()}>
                        Voir plus
                    </small>
                </Stack>
            </Stack>
            <FormControl fullWidth required>
                <Label label={"Identification internationale (IBAN)"} />
                <TextField onChange={(event: any) => setStepData("iban", event.target.value)} variant="outlined" placeholder='FRXX XXXX XXXX XXXX XXXX XXXX XXX' value={data["iban"]} />
            </FormControl>
            <FormControl fullWidth required>
                <Label label={"Code international d'identification de votre banque (BIC)"} />
                <TextField onChange={(event: any) => setStepData("bic", event.target.value)} variant="outlined" placeholder='Bic' value={data["bic"]} />
            </FormControl>
            <Stack direction={'row'} gap={"10px"} width={"100%"} alignItems={"end"}>
                <FormControl fullWidth required >
                    <Label label={"Date du paiement"} />
                    <ButtonGroup variant="outlined" sx={{ py: 2 }}>
                        {
                            lstJourPrelevement?.map((btn: any, index: number) => (
                                <Button key={index} className={btn === data["jour_prelevement"] ? 'btn-group-active' : 'btn-group'} onClick={() => setStepData("jour_prelevement", btn)}>
                                    {btn}
                                </Button>
                            ))
                        }
                    </ButtonGroup>
                </FormControl>
                <FormControl fullWidth required>
                    <Label label={"Périodicité du paiement"} />
                    <ButtonGroup variant="outlined" sx={{ py: 2 }}>
                        <Button className={'btn-group-active'}>
                            {data["frequence_paiement"]}
                        </Button>
                    </ButtonGroup>
                </FormControl>
            </Stack>
            <FormControlLabel control={<Checkbox value={data["accept_conditions"]} onChange={() => setStepData("accept_conditions", !data["accept_conditions"])} />}
                label={
                    <small className='checkbox-label'>
                        Je déclare avoir lu et accepté les conditions de l’offre et avoir pris connaissance de la notice valant conditions générales du produit
                        ainsi que les <Link to="/mentions-legales" target="_blank" rel="noreferrer" className='link' >conditions générales de vente</Link>
                    </small>
                }
            />
            <FormControlLabel control={<Checkbox value={data["accept_data"]} onChange={() => setStepData("accept_data", !data["accept_data"])} />}
                label={
                    <small className='checkbox-label'>
                        Je déclare sincères et exacts les renseignements indiqués dans le cadre du processus de souscription en ligne qui serviront de base à l’établissement de mon contrat. J’accepte que mes données personnelles, en particulier relatives à ma santé, fassent l'objet d'un traitement à des fins de passation, gestion et exécution de mon contrat d'assurance
                    </small>
                }
            />
        </Stack>
    )
};