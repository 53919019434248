/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputAdornment, MenuItem, OutlinedInput, Select, Slide, Stack } from '@mui/material';
import { CiEdit } from "react-icons/ci";
import { AiOutlineClose } from "react-icons/ai";
import Label from '../../Label/Label';
import Error from '../../Error/Error';
import colors from '../../../colors/colors';
import { DatePicker } from '@mui/x-date-pickers';
import { changeCurrentErrorMessage, changeIsButtonEnabled, changeStepsDataEmprunteur } from '../../../redux/actions';
import { PiPlusCircleLight } from 'react-icons/pi';
import { FiBarChart2 } from 'react-icons/fi';
import { MdErrorOutline } from 'react-icons/md';
import '../ParcoursEmprunteur.scss';


export default function InfosPret() {
    const dispatch = useDispatch();
    const stepEmprunteur = useSelector((store: any) => store.stepEmprunteur.stepEmprunteur);
    const currentSubstep = useSelector((store: any) => store.currentSubstep.currentSubstep);
    const initDataEmprunteur = useSelector((store: any) => store.initDataEmprunteur.initDataEmprunteur);
    const stepsDataEmprunteur = useSelector((store: any) => store.stepsDataEmprunteur.stepsDataEmprunteur);
    const currentErreurMessage = useSelector((store: any) => store.currentErreurMessage.currentErreurMessage);

    const [data, setData] = useState<any>(stepsDataEmprunteur["informations-pret"]);
    const [dataPret, setDataPret] = useState<any>(stepsDataEmprunteur["informations-pret"]["prets"]);
    const [pretsToDelete, setPretsToDelete] = useState<any>(stepsDataEmprunteur["informations-pret"]["prets_to_delete"]);
    const [activePret, setActivePret] = useState<number>(stepsDataEmprunteur["informations-pret"]["prets"].length - 1);
    const [lstTypePret] = useState<any>(initDataEmprunteur["ugip_type_pret"]);


    useEffect(() => {
        window.scroll(0, 0);
        dispatch(changeIsButtonEnabled(false));
        return () => {
            dispatch(changeCurrentErrorMessage(currentErreurMessage ?? null));
            dispatch(changeIsButtonEnabled(false));
            dispatch(changeCurrentErrorMessage(null));
        }
    }, []);

    useEffect(() => {
        dispatch(changeStepsDataEmprunteur({ ...stepsDataEmprunteur, "informations-pret": data }));
        if (isStepDataValid()) {
            dispatch(changeIsButtonEnabled(true));
        } else {
            dispatch(changeIsButtonEnabled(false));
        }
    }, [data]);

    useEffect(() => {
        setData((prevState: any) => ({ ...prevState, prets: dataPret }));
    }, [dataPret]);

    useEffect(() => {
        setData((prevState: any) => ({ ...prevState, prets_to_delete: pretsToDelete }));
    }, [pretsToDelete]);


    useEffect(() => {
        if (isStepDataValid()) {
            dispatch(changeIsButtonEnabled(true));
        } else {
            dispatch(changeIsButtonEnabled(false));
        }
    }, [currentSubstep]);


    const handleDataPret = (index: number, key: string, value: any) => {
        setDataPret((prevState: any) => prevState.map((obj: any, i: number) => {
            if (i === index) {
                return {
                    ...obj,
                    [key]: value
                };
            }
            return obj;
        }));
    };

    const addNewPret = () => {
        if (isStepDataValid()) {
            const newPret = {
                montant_pret: "",
                taux_pret: "",
                date_premiere_echeance: "",
                duree_pret: "",
                type_pret: "Amortissable",
                ugip_amortissement: "Semestriel",
                ugip_duree_differe: 0
            };
            setDataPret((prevState: any) => {
                const updatedDataPret = [...prevState, newPret];
                setActivePret(updatedDataPret.length - 1);
                return updatedDataPret;
            });
        } else {
            dispatch(changeCurrentErrorMessage("Veuillez d'abord remplir tous les champs avant d'ajouter un autre prêt !"));
        }
    };

    const removePret = (index: number) => {
        setDataPret((prevState: any) => {
            const pretToRemove = prevState[index];
            const updatedDataPret = prevState.filter((_: any, i: number) => i !== index);
            if (pretToRemove.hasOwnProperty("id")) {
                setPretsToDelete((prevToDelete: any) => [...prevToDelete, pretToRemove["id"]]);
            }
            setActivePret(updatedDataPret.length - 1);
            return updatedDataPret;
        });
    };

    const getListDureeDiffere = () => {
        const months = [{ label: "Sans différé", value: 0 }];
        for (let i = 1; i <= 24; i++) {
            months.push({ label: `${i} mois`, value: i });
        };
        return months;
    };

    const isDataPretValid = (pret: any) => {
        return (
            pret["date_premiere_echeance"] && pret["taux_pret"] !== "" && parseFloat(pret["taux_pret"]) > 0 &&
            pret["montant_pret"] !== "" && pret["duree_pret"] !== "" && pret["ugip_duree_differe"] !== "" &&
            parseInt(pret["duree_pret"]) > parseInt(pret["ugip_duree_differe"]) &&
            pret["type_pret"] !== "" && pret["ugip_amortissement"] !== ""
        )
    };

    const isStepDataValid = () => {
        if (currentSubstep === 0) {
            return (
                dataPret.filter((pret: any) => !pret.hasOwnProperty('is_deletable')).length > 0 &&
                dataPret.every((pret: any) => {
                    return (
                        pret["date_premiere_echeance"] && pret["taux_pret"] !== "" && parseFloat(pret["taux_pret"]) > 0 &&
                        pret["montant_pret"] !== "" && pret["duree_pret"] !== "" && pret["ugip_duree_differe"] !== "" &&
                        parseInt(pret["duree_pret"]) > parseInt(pret["ugip_duree_differe"]) &&
                        pret["type_pret"] !== "" && pret["ugip_amortissement"] !== ""
                    )
                })
            );
        }
    };


    return (
        <Stack spacing={2} mt={6}>
            {currentErreurMessage && (<Error error={currentErreurMessage} />)}
            {((): any => {
                if (currentSubstep === 0) {
                    return (
                        <Slide in={stepEmprunteur === 1} {...({ timeout: 1000 })} direction='left'>
                            <Stack>
                                <h3 className='m-0' style={{ fontSize: 25 }}>
                                    Prêt(s) immobilier à assurer
                                </h3>
                                {
                                    dataPret?.map((_pret: any, index: number) => (
                                        activePret === index ? (
                                            <Stack width={"100%"} spacing={2} mt={4} key={index}>
                                                {
                                                    index !== 0 && (
                                                        <h3 className='m-0' style={{ fontSize: 25 }}>
                                                            Prêt à assurer Nº{index + 1}
                                                        </h3>
                                                    )
                                                }
                                                <Stack direction={'row'} gap={"10px"} width={"100%"} alignItems={"end"}>
                                                    <FormControl fullWidth required>
                                                        <Label label={"Type de prêt"} fontFamily={"century-bold"} />
                                                        <Select
                                                            className='input-emp'
                                                            onChange={(event: any) => handleDataPret(index, "type_pret", event.target.value)}
                                                            input={<OutlinedInput value={dataPret[index]["type_pret"]} />}
                                                        >
                                                            {
                                                                lstTypePret?.map((pn: any, index: number) => (
                                                                    <MenuItem value={pn} key={index}>{pn}</MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl fullWidth required>
                                                        <Label label={"Montant du prêt"} fontFamily={"century-bold"} />
                                                        <OutlinedInput className='input-emp' placeholder='200000' type="number" endAdornment={<InputAdornment position="end">€</InputAdornment>} onChange={(event: any) => handleDataPret(index, "montant_pret", event.target.value)} value={dataPret[index]["montant_pret"] ?? ""} />
                                                    </FormControl>
                                                </Stack>
                                                <Stack direction={'row'} gap={"10px"} width={"100%"} alignItems={"end"}>
                                                    <FormControl fullWidth required>
                                                        <Label label={"Taux"} fontFamily={"century-bold"} />
                                                        <OutlinedInput placeholder='1,00' className='input-emp' type="number" endAdornment={<InputAdornment position="end">%</InputAdornment>} onChange={(event: any) => handleDataPret(index, "taux_pret", event.target.value)} value={dataPret[index]["taux_pret"] ?? ""} />
                                                    </FormControl>
                                                    <FormControl fullWidth required>
                                                        <Label label={"Durée totale"} fontFamily={"century-bold"} />
                                                        <OutlinedInput placeholder='240' className='input-emp' endAdornment={<InputAdornment position="end">mois</InputAdornment>} onChange={(event: any) => handleDataPret(index, "duree_pret", event.target.value)} value={dataPret[index]["duree_pret"] ?? ""} />
                                                    </FormControl>
                                                    <FormControl fullWidth required>
                                                        <Label label={"Dont différé"} fontFamily={"century-bold"} />
                                                        <Select
                                                            className='input-emp'
                                                            onChange={(event: any) => handleDataPret(index, "ugip_duree_differe", event.target.value)}
                                                            input={<OutlinedInput value={dataPret[index]["ugip_duree_differe"]} />}
                                                        >
                                                            {
                                                                getListDureeDiffere().map((pn: any, index: number) => (
                                                                    <MenuItem value={pn.value} key={index}>{pn.label}</MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Stack>
                                                <Stack direction={'row'} gap={"10px"} width={"100%"} alignItems={"end"}>
                                                    <FormControl fullWidth required>
                                                        <Label label={"Première échéance"} fontFamily={"century-bold"} />
                                                        <DatePicker value={dayjs(dataPret[index]["date_premiere_echeance"]) ?? ""} className='input-emp'
                                                            onChange={(value: any) => handleDataPret(index, "date_premiere_echeance", moment(value.toString()).format("YYYY-MM-DD"))}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Stack>
                                        ) : (
                                            <Stack key={index} mt={index !== 0 ? 5 : 0}>
                                                {
                                                    index !== 0 && (
                                                        <h3 className='m-0' style={{ fontSize: 25 }}>
                                                            Prêt à assurer Nº{index + 1}
                                                        </h3>
                                                    )
                                                }
                                                <Stack className='form-section' px={2} py={3} mt={2}>
                                                    <Stack direction={"row"} alignItems={"center"} spacing={1} justifyContent={"space-between"}>
                                                        {
                                                            isDataPretValid(_pret) ? (
                                                                <Stack direction={"row"} alignItems={"end"} spacing={2}>

                                                                    <FiBarChart2 style={{ fontSize: 25, color: colors.blue1 }} />
                                                                    <Stack gap={1}>
                                                                        <small className='m-0 century' style={{ fontSize: 18 }}>{dataPret[index]["montant_pret"]} <sup>€</sup></small>
                                                                        <small className='century'>{dataPret[index]["taux_pret"]} % . {dataPret[index]["duree_pret"]} mois</small>
                                                                    </Stack>
                                                                </Stack>
                                                            ) : (
                                                                <Stack bgcolor={"#f8d7da"} py={0.5} px={2} borderRadius={50} borderColor={"#f5c6cb"}>
                                                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                                                        <small className='m-0 century-bold align-center'
                                                                            style={{ color: "#7c2932", fontSize: 13, display: "flex", alignItems: "center" }}>
                                                                            <MdErrorOutline size={20} style={{ marginRight: 4 }} />
                                                                            Informations manquantes sur ce prêt !
                                                                        </small>
                                                                    </Stack>
                                                                </Stack>
                                                            )
                                                        }
                                                        <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                                            <CiEdit className='cursor-pointer' onClick={() => setActivePret(index)} style={{ fontSize: 30, color: "#aaa8a1" }} />
                                                            <AiOutlineClose className='cursor-pointer' onClick={() => removePret(index)} style={{ fontSize: 30, color: "#aaa8a1" }} />
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        )
                                    ))
                                }
                                {
                                    dataPret.length < 6 && (
                                        <Stack mt={2} justifyContent={"center"} alignItems={"center"}>
                                            <Stack onClick={addNewPret} alignItems={"center"} className='cursor-pointer' direction={"row"} spacing={1}>
                                                <PiPlusCircleLight style={{ fontSize: 30, color: colors.blue1 }} />
                                                <p className='century-bold'>Renseigner un autre prêt</p>
                                            </Stack>
                                        </Stack>
                                    )
                                }
                            </Stack>
                        </Slide>
                    )
                }
            })()}
        </Stack>
    )
};