import { createSearchParams, useNavigate } from 'react-router-dom';
import { Button, Grid, Stack, Box, useTheme, useMediaQuery } from '@mui/material';
import TableEmprunteur from '../../components/TableEmprunteur/TableEmprunteur';
import ContactForm from '../../components/ContactForm/ContactForm';
import { Container } from '@mui/system';
import familleEmprunteurImage from "../../assets/images/famille_emprunteur.png";
import ugipLogo from "../../assets/logos/UGIP_ASSURANCES_Logo_horizontal_RVB 1.svg";
import aprilLogo from "../../assets/logos/APRIL_QUADRI_BLEU_RVB[1] 1.svg";
import neolianeLogo from "../../assets/logos/Logo_Néoliane_RVB.png";
import apviaLogo from "../../assets/logos/apivia.png";
import generaliLogo from "../../assets/logos/1200px-Assicurazioni_Generali_(logo).svg.png";
import micLogo from "../../assets/logos/Logo-MIC_courrier.png";
import resiliationIcon from "../../assets/icons/résiliation.svg";
import suppressionIcon from "../../assets/icons/suppression.svg";
import oubliIcon from "../../assets/icons/droit oubli.svg";
import colors from '../../colors/colors';
import Reussite from '../../components/Reussite/Reussite';
import { CustomContainer } from '../../components/CustomContainer/CustomContainer';
import ParcoursService from '../../services/services';
import { changeCurrentErrorMessage, changeStepEmprunteur } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import './Emprunteur.scss';


const stepList = {
    "informations-bien": 0,
    "informations-pret": 1,
    "informations-personnelles": 2,
    "offre-emprunteur": 3,
    "informations-complementaires": 4,
    "signature": 5
};


function Emprunteur() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const product = "DevisEmprunteurClient";
    let erreur = `
        Une erreur inconnue s'est produite, 
        veuillez attendre un peu puis réessayer ou contactez nous à contact@julia-assurance.fr !
    `;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const apiCheckStepManager = async () => {
        if (localStorage.getItem('secu_id_emprunteur')) {
            await ParcoursService.api_check_step_manager(product).then(async (data: any) => {
                if (data['new'] !== null) {
                    if (data['new']) {
                        apiCreateStepManager();
                    } else {
                        dispatch(changeStepEmprunteur(stepList[data["step"] as keyof object]));
                        navigate({ pathname: "/devis-emprunteur", search: `?${createSearchParams({ come_back: 'true' })}` });
                    }
                }
            }).catch(() => {
                console.log("__error_check__");
                dispatch(changeCurrentErrorMessage(erreur));
            });
        } else {
            apiCreateStepManager();
        }
    };

    const apiCreateStepManager = async () => {
        ParcoursService.api_create_step_manager(product).then(async (data: any) => {
            localStorage.setItem('secu_id_emprunteur', data['numero_devis']);
            localStorage.setItem('private_id_emprunteur', data['private_id']);
            dispatch(changeStepEmprunteur(stepList[data["next_step"] as keyof object]));
            navigate("/devis-emprunteur");
        }).catch(() => {
            console.log("__error_create__")
            dispatch(changeCurrentErrorMessage(erreur));
        });
    };


    return (
        <div style={{ overflowX: "hidden" }}>
            <CustomContainer>
                <Stack width={"100%"} pt={{ xs: 12, md: 20 }}>
                    <Stack direction={"column"} justifyContent={"left"} display={{ md: "none", xs: "flex" }}>
                        <h3 style={{ marginBottom: 10 }}>Nos solutions d'assurance de prêt</h3>
                        <hr style={{ color: "#149db1", width: 50, margin: 0, height: 4, backgroundColor: "#149db1" }} />
                    </Stack>
                    <Grid container>
                        <Grid item md={6} >
                            <Box component="img" src={familleEmprunteurImage} alt="famille" width={{ md: "unset", xs: "100%" }} />
                        </Grid>
                        <Grid item md={6} >
                            <Stack direction={"column"} justifyContent={"left"} display={{ md: "flex", xs: "none" }}>
                                <h3 style={{ marginBottom: 10 }}>Nos solutions d'assurance de prêt</h3>
                                <hr style={{ color: "#149db1", width: 50, margin: 0, height: 4, backgroundColor: "#149db1" }} />
                            </Stack>
                            <h1> <span onClick={() => apiCheckStepManager()} className={"bg-pink"}>Changez</span> enfin l'assurance de votre crédit immobilier</h1>
                            <p>Pourquoi conserver l’assurance de votre banque lorsque vous pouvez économiser en moyenne 1 300 € en quelques clics ? Vous pouvez en changer !</p>
                            <Button className="btn-decouvrir-blue" variant="contained" fullWidth={matches ?? false} onClick={() => navigate("/devis-emprunteur-simple")}>
                                Découvrir mes économies
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            </CustomContainer>
            <Container className={"px-0"} maxWidth={false} sx={{ py: { md: 15, xs: 5 } }}>
                <CustomContainer>
                    <Stack pt={2}>
                        <h2>Des risques <span className={"bg-doodle-circle"}>couverts</span> pour des garanties adaptées</h2>
                        <TableEmprunteur />
                    </Stack>
                </CustomContainer>
            </Container>
            <Container className={"bg-emprunteur px-0"} maxWidth={false} sx={{ py: { md: 15, xs: 8 } }}>
                <CustomContainer>
                    <h2 className='h2-white'>
                        Le <span className={`${!matches ? "bg-highlight" : ""}`}>changement</span> rapide et simple, c'est desormais <span className={`${matches ? "bg-highlight" : ""}`}>possible</span> !
                    </h2>
                    <p className='p-white' style={{ fontSize: !matches ? "18px" : "16px", marginTop: !matches ? 90 : 50 }}>Depuis septembre 2022, la loi Lemoine vous permet de changer d'assurance de prêt à tout moment et sans frais !</p>
                    <Grid container mt={1} spacing={{ md: 6, xs: 3 }} textAlign={{ md: "center", xs: "left" }}>
                        <Grid item md={4} xs={12}>
                            <Box component="img" pb={2.5} src={resiliationIcon} alt="resiliation icon" width={{ md: "70px", xs: "40px" }} />
                            <h3 className="h3-white" style={{ paddingBottom: 5 }}>Résiliation à tout moment</h3>
                            <p className='p-white' style={{ fontSize: !matches ? "18px" : "16px" }}>
                                Vous avez la <span className='p-bold'> liberté de changer d'assurance de prêt à tout moment </span>, sans délai de préavis, à condition de souscrire à un contrat offrant des garanties équivalentes.
                            </p>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box component="img" pb={2.5} src={suppressionIcon} alt="suppression icon" width={{ md: "70px", xs: "40px" }} />
                            <h3 className="h3-white" style={{ paddingBottom: 5 }}>Suppression du questionnaire de santé</h3>
                            <p className='p-white' style={{ fontSize: !matches ? "18px" : "16px" }}>
                                Cette mesure s'applique aux prêts <span className='p-bold'> inférieurs à 200 000 € </span> dont l'échéance de remboursement est prévue avant le 60e anniversaire de l'assuré.
                            </p>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box component="img" pb={2.5} src={oubliIcon} alt="oubli icon" width={{ md: "70px", xs: "40px" }} />
                            <h3 className="h3-white" style={{ paddingBottom: 5 }}>Extension du droit à l'oubli</h3>
                            <p className='p-white' style={{ fontSize: !matches ? "18px" : "16px" }}>
                                Vous n'êtes plus tenu de déclarer une maladie cancéreuse ou une hépatite C si le protocole thérapeutique est terminé depuis 5 ans et qu'aucune rechute n'a été observée.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container mt={6} justifyContent={"center"} display={{ md: "grid", xs: "none" }}>
                        <Button className="btn-decouvrir-pink" variant="contained" fullWidth={matches ?? false} onClick={() => navigate("/devis-emprunteur-simple")}>
                            Découvrir mes économies
                        </Button>
                    </Grid>
                </CustomContainer>
            </Container>
            <Container className={`px-0 ${!matches ? "half-background-list" : "bg-blue-list-emprunteur"}`} maxWidth={false}>
                <CustomContainer>
                    <Grid container bgcolor={colors.blue}>
                        <Grid container item md={5} xs={12} bgcolor={colors.blue} pl={{ md: 5, xs: 0 }} pr={{ md: 30, xs: 0 }} py={{ md: 7, xs: 0 }} alignItems={"center"}>
                            <Stack direction={"column"} alignItems={"baseline"}>
                                <h2 className='h2-white'>Une expérience client facilitée grâce à nos conseillers</h2>
                            </Stack>
                        </Grid>
                        <Grid item md={7} xs={12} bgcolor={{ md: "white", xs: colors.blue }} sx={{ borderTopLeftRadius: 20 }}>
                            <Stack bgcolor={"transparent"} height={"100%"} justifyContent={{ md: "space-between", xs: "unset" }} ml={{ md: -15, xs: 0 }} mt={{ md: 6, xs: 4 }} spacing={{ md: 2.5, xs: 4 }}>
                                <Stack direction={"row"} spacing={{ md: 5, xs: 2 }} alignItems={"center"} bgcolor={colors.blue2} py={{ md: 4, xs: 1 }} pl={{ md: 10, xs: 2 }} borderRadius={{ md: "20px", xs: "10px" }}>
                                    <h1 className='h1-white' style={{ fontSize: matches ? 55 : 65 }}>1</h1>
                                    <p className='p-white' style={{ fontSize: matches ? 16 : 18 }}>Choisissez votre nouvelle assurance de prêt.</p>
                                </Stack>
                                <Stack direction={"row"} spacing={{ md: 5, xs: 2 }} alignItems={"center"} bgcolor={colors.blue2} py={{ md: 4, xs: 1 }} pl={{ md: 10, xs: 2 }} borderRadius={{ md: "20px", xs: "10px" }}>
                                    <h1 className='h1-white' style={{ fontSize: matches ? 55 : 65 }}>2</h1>
                                    <p className='p-white' style={{ fontSize: matches ? 16 : 18 }}>Signez facilement votre contrat en ligne</p>
                                </Stack>
                                <Stack direction={"row"} spacing={{ md: 5, xs: 2 }} alignItems={"center"} bgcolor={colors.blue2} py={{ md: 4, xs: 1 }} pl={{ md: 10, xs: 2 }} borderRadius={{ md: "20px", xs: "10px" }}>
                                    <h1 className='h1-white' style={{ fontSize: matches ? 55 : 65 }}>3</h1>
                                    <p className='p-white' style={{ fontSize: matches ? 16 : 18 }}>Suivez vos sinistres.</p>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container mt={6} justifyContent={"center"} display={{ md: "none", xs: "grid" }}>
                        <Button className="btn-decouvrir-pink" variant="contained" fullWidth={matches ?? false} onClick={() => navigate("/devis-emprunteur-simple")}>
                            Découvrir mes économies
                        </Button>
                    </Grid>
                </CustomContainer>
            </Container>
            <CustomContainer>
                <Grid container bgcolor={"transparent"} px={{ md: 0, xs: 4 }}>
                    <Grid container item md={5} xs={12} pr={{ md: 25, xs: 0 }} alignItems={"center"} mt={{ md: -8, xs: -16 }}>
                        <Stack p={4} className='box-confiance' zIndex={0}>
                            <h3 className='text-magenta m-0'>Le + pour vous</h3>
                            <p className='m-0'>Nous nous occupons de toutes les démarches de résiliation auprès de votre ancien assureur !</p>
                        </Stack>
                    </Grid>
                </Grid>
            </CustomContainer>
            <Grid container mt={2} justifyContent={"center"} display={{ md: "grid", xs: "none" }}>
                <Button className="btn-decouvrir-pink" variant="contained" onClick={() => navigate("/devis-emprunteur-simple")}>
                    Recevoir mes économies
                </Button>
            </Grid>
            <Container className={"px-0"} maxWidth={false} sx={{ py: { md: 10, xs: 6 } }}>
                <CustomContainer>
                    <Stack width={"100%"}>
                        <h2>Nos partenaires assureurs de <span className='bg-doodle-green'>confiance</span> </h2>
                        <Stack py={1}>
                            <p>
                                Chez Julia Assurance, nous sommes fiers de collaborer avec des assureurs de confiance, soigneusement sélectionnés, afin de <span className='p-bold'> vous offrir les meilleures garanties </span> en matière d'assurance de prêt.
                                <br /> <br /> Nous partageons avec eux un engagement commun envers la satisfaction du client et la protection de votre projet immobilier. Lorsque vous choisissez Julia Assurances, vous bénéficiez de <span className='p-bold'> l'expertise et de la réputation des meilleurs assureurs du marché.</span> Ensemble, nous veillons à vous offrir une protection optimale pour vous accompagner sereinement dans vos projets.
                            </p>
                        </Stack>
                        <Stack direction={"row"} py={{ md: 8, xs: 4 }} alignItems={"center"} justifyContent={"center"} spacing={{ md: 12, xs: 4 }} flexWrap={"wrap"} >
                            <Box component="img" src={ugipLogo} alt="ugip" width={{ md: 120, xs: 80 }} />
                            <Box component="img" src={aprilLogo} alt="april" width={{ md: 120, xs: 80 }} />
                            <Box component="img" src={neolianeLogo} alt="neoliane" width={{ md: 120, xs: 80 }} />
                            <Box component="img" src={apviaLogo} alt="apivia" width={{ md: 120, xs: 80 }} />
                            <Box component="img" src={generaliLogo} alt="generali" width={{ md: 120, xs: 80 }} />
                            <Box component="img" src={micLogo} alt="mic" width={{ md: 120, xs: 80 }} />
                        </Stack>
                    </Stack>
                </CustomContainer>
            </Container>
            <Reussite />
            <ContactForm />
        </div>
    );
};


export default Emprunteur;