/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Stack, RadioGroup, FormControlLabel, Radio, TextField, FormControl, Select, MenuItem, OutlinedInput, ButtonGroup, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Label from '../../Label/Label';
import Error from '../../Error/Error';
import { useDispatch, useSelector } from 'react-redux';
import { changeCurrentErrorMessage, changeIsButtonEnabled, changeStepsData } from '../../../redux/actions';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import '../ParcoursSante.scss';


const codePostalRegex = new RegExp('^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B))[0-9]{3}$');
const phoneNumberRegex = new RegExp(`^(0|(00|\\+)33)[123456789][0-9]{8}$`);
const emailRegEx = new RegExp('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}');

const buttons = [
    { index: 0, title: "Vous" }, { index: 1, title: "Votre conjoint" }, { index: 2, title: "Vos enfants" }
];

const getValueForm = (data: any) => {
    const values = Object.entries(data).reduce((acc: any, [key, value]: any) => {
        if (value && value.hasOwnProperty("value")) {
            acc[key as keyof object] = value.value;
        }
        return acc;
    }, {});
    return values;
};


export default function InfosComplementaires() {
    const dispatch = useDispatch();
    const initData = useSelector((store: any) => store.initData.initData);
    const currentErreurMessage = useSelector((store: any) => store.currentErreurMessage.currentErreurMessage);
    const stepsData = useSelector((store: any) => store.stepsData.stepsData);
    const [dataConjoint, setDataConjoint] = useState<any>(
        stepsData["informations-sante"]["has_conjoint"] ? getValueForm(stepsData["informations-sante"]["conjoint"]) : {}
    );
    const [data, setData] = useState<any>(stepsData["informations-sante"]);
    const [dataEnfant, setDataEnfant] = useState<any>(
        data["enfants"].map((enfant: any) => getValueForm(enfant))
    );
    const [activeButton, setActiveButton] = useState<any>(0);

    const [lstProfession] = useState<any>(initData["lst_profession"] ? Object.values(initData["lst_profession"]) : []);
    const [lstSituationFamiliale] = useState<any>(initData["lst_situation_familiale"] ? Object.values(initData["lst_situation_familiale"]) : []);
    const [lstPaysNaissance] = useState<any>(initData["lst_pays"]);
    const [lstNationalite] = useState<any>(initData["lst_nationalite"]);
    const [lstRegime] = useState<any>(initData["lst_regime"]);


    useEffect(() => {
        window.scroll(0, 0);
        dispatch(changeIsButtonEnabled(false));
        const inputElement = document.querySelector('.google-places-autocomplete__input');
        if (inputElement) {
            inputElement.addEventListener('input', (event: any) => {
                handleAdresse({ adresse: event.target.value });
            });
        }
        return () => {
            dispatch(changeCurrentErrorMessage(currentErreurMessage ?? null));
            dispatch(changeIsButtonEnabled(false));
            if (inputElement) {
                inputElement.removeEventListener('input', handleAdresse);
            }
        }
    }, []);

    useEffect(() => {
        dispatch(changeStepsData({ ...stepsData, "informations-sante": data }));
        if (isStepDataValid()) {
            if (!stepsData["informations-sante"]["has_conjoint"]) {
                removeConjointKey();
            }
            dispatch(changeIsButtonEnabled(true));
        } else {
            dispatch(changeIsButtonEnabled(false));
        }
    }, [data]);

    useEffect(() => {
        setData((prevState: any) => ({ ...prevState, conjoint: dataConjoint }));
    }, [dataConjoint]);

    useEffect(() => {
        setData((prevState: any) => ({ ...prevState, enfants: dataEnfant }));
    }, [dataEnfant]);


    const handleDataConjoint = (key: string, value: any) => {
        setDataConjoint((prevState: any) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleDataEnfant = (index: number, key: string, value: any) => {
        setDataEnfant((prevState: any) => prevState.map((obj: any, i: number) => {
            if (i === index) {
                return {
                    ...obj,
                    [key]: value
                };
            }
            return obj;
        }));
    };

    const setStepData = (key: string, value: any) => {
        setData((prevState: any) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const removeConjointKey = () => {
        const newData = { ...data };
        if (newData.hasOwnProperty("conjoint")) {
            delete newData["conjoint"];
            setData(newData);
        }
    };

    const isStepDataValid = () => {
        const isValidDateEffet = data['date_effet'] && !isNaN(Date.parse(data['date_effet']));
        const isValidGender = data["gender"] === 'Homme' || data["gender"] === 'Femme';
        const isValidProfession = data["profession"] !== '';
        const isValidSituationFamiliale = data["situation_familiale"] !== '';
        const isValidSecuriteSociale = data["securite_sociale"] !== '';
        const isValidNomNaissance = data["nom_naissance"] !== '';
        const isValidCodePostalNaissance = codePostalRegex.test(data["code_postal_naissance"]);
        const isValidVilleNaissance = data["ville_naissance"] !== '';
        const isValidPaysNaissance = data["pays_naissance"] !== '';
        const isValidNationalite = data["nationalite"] !== '';
        const isValidNom = data["nom"] !== '';
        const isValidPrenom = data["prenom"] !== '';
        const isValidDateNaissance = data["date_naissance"] && !isNaN(Date.parse(data["date_naissance"]));
        const isValidRegimeSocial = data["regime_social"] !== '';
        const isValidTelephone = phoneNumberRegex.test(data["telephone"]);
        const isValidEmail = emailRegEx.test(data["email"]);
        const isValidAdresse = (codePostalRegex.test(data["adresse_actuelle"]["code_postal"]) &&
            data["adresse_actuelle"]["adresse"] !== "" && data["adresse_actuelle"]["adresse"] !== null &&
            data["adresse_actuelle"]["ville"] !== "" && data["adresse_actuelle"]["ville"] !== null
        );

        const isValidDataConjoint = (
            data["has_conjoint"] ?
                (
                    dataConjoint["nom"] !== "" && dataConjoint["prenom"] !== "" &&
                    dataConjoint["nom_naissance"] !== "" && dataConjoint["securite_sociale"] !== "" &&
                    dataConjoint["date_naissance"] !== "" && dataConjoint["regime_social"] !== "" && dataConjoint["gender"] !== ""
                ) : true
        )

        const isValidDataEnfant = (
            data["has_enfant"] ?
                dataEnfant.every((enfant: any) => (
                    enfant["nom"] !== "" && enfant["prenom"] !== "" &&
                    enfant["securite_sociale"] !== "" &&
                    enfant["date_naissance"] !== "" && enfant["regime_social"] !== "" && enfant["gender"] !== ""
                ))
                : true
        )

        return (
            isValidDateEffet && isValidGender && isValidProfession && isValidSituationFamiliale && isValidSecuriteSociale && isValidNomNaissance &&
            isValidCodePostalNaissance && isValidVilleNaissance && isValidPaysNaissance && isValidNationalite && isValidNom &&
            isValidPrenom && isValidDateNaissance && isValidRegimeSocial && isValidTelephone && isValidEmail && isValidAdresse &&
            isValidDataConjoint && isValidDataEnfant
        );
    };

    const handleAdresse = (addressObject: { [key: string]: any }) => {
        const newData = { ...data };
        const updatedAdresseActuelle = { ...newData.adresse_actuelle };

        for (const key in addressObject) {
            if (addressObject.hasOwnProperty(key)) {
                updatedAdresseActuelle[key] = addressObject[key];
            }
        }
        setStepData("adresse_actuelle", updatedAdresseActuelle);
    };



    const handleSelectAdresse = (suggestion: any) => {
        const adresseObject = {
            adresse: suggestion.description,
            ville: suggestion.terms.length > 1 ? suggestion.terms[suggestion.terms.length - 2].value : suggestion.terms[0].value
        }
        handleAdresse(adresseObject);
    };


    return (
        <>
            {currentErreurMessage && (<Error error={currentErreurMessage} />)}
            {
                (data["has_enfant"] || data["has_conjoint"]) && (
                    <Stack width={"100%"} alignItems={"center"}>
                        <ButtonGroup variant="outlined" sx={{ py: 3 }}>
                            {
                                buttons?.map((btn: any, index: number) => (
                                    <Button key={index} className={btn.index === activeButton ? 'btn-group-active' : 'btn-group'} onClick={() => setActiveButton(btn.index)}>
                                        {btn.title}
                                    </Button>
                                ))
                            }
                        </ButtonGroup>
                    </Stack>
                )
            }
            {
                activeButton === 0 && (
                    <Stack width={"100%"} spacing={2}>
                        <>
                            <h3 className='century-bold m-0'>Informations complémentaires</h3>
                            <Stack flexWrap={'wrap'} direction={'row'} columnGap={"35px"} rowGap={"18px"} pb={5} alignItems={"end"}>
                                <FormControl required>
                                    <Label label={"Date effet du contrat"} />
                                    <DatePicker value={dayjs(data["date_effet"])}
                                        onChange={(value: any) => setStepData("date_effet", moment(value.toString()).format("YYYY-MM-DD"))}
                                    />
                                </FormControl>
                                <RadioGroup
                                    sx={{ columnGap: 3, rowGap: 1.5 }}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={(event: any) => setStepData("gender", event.target.value)}
                                >
                                    <FormControlLabel className={"radio-item"} value="Homme" checked={(data["gender"] === "Homme")} control={<Radio />} label="Homme" labelPlacement="start" />
                                    <FormControlLabel className={"radio-item"} value="Femme" checked={(data["gender"] === "Femme")} control={<Radio />} label="Femme" labelPlacement="start" />
                                </RadioGroup>
                                <Stack direction={'row'} gap={"10px"} width={"100%"} alignItems={"end"}>
                                    <FormControl fullWidth required>
                                        <Label label={"Profession"} />
                                        <Select
                                            labelId="demo-simple-select-label"
                                            onChange={(event: any) => setStepData("profession", event.target.value)}
                                            input={<OutlinedInput value={data["profession"]} />}
                                        >
                                            {
                                                lstProfession?.map((rg: any, index: number) => (
                                                    <MenuItem value={rg} key={index}>{rg}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth required>
                                        <Label label={"Situation familiale"} />
                                        <Select
                                            labelId="demo-simple-select-label"
                                            onChange={(event: any) => setStepData("situation_familiale", event.target.value)}
                                            input={<OutlinedInput value={data["situation_familiale"]} />}
                                        >
                                            {
                                                lstSituationFamiliale?.map((sf: any, index: number) => (
                                                    <MenuItem value={sf} key={index}>{sf}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <FormControl fullWidth required>
                                    <Label label={"Votre Nº de sécurité sociale"} />
                                    <TextField onChange={(event: any) => setStepData("securite_sociale", event.target.value)} variant="outlined" placeholder='Numéro de sécurité sociale' value={data["securite_sociale"]} />
                                </FormControl>
                            </Stack>
                        </>
                        <>
                            <h3 className='century-bold m-0'>Vos coordonnées de naissance</h3>
                            <FormControl fullWidth required>
                                <Label label={"Nom de naissance"} />
                                <TextField onChange={(event: any) => setStepData("nom_naissance", event.target.value)} variant="outlined" value={data["nom_naissance"]} />
                            </FormControl>
                            <Stack direction={'row'} gap={"10px"} width={"100%"} alignItems={"end"}>
                                <FormControl fullWidth required>
                                    <Label label={"Code postal de naissance"} />
                                    <TextField onChange={(event: any) => setStepData("code_postal_naissance", event.target.value)} placeholder="XXXXX" variant="outlined" value={data["code_postal_naissance"]} />
                                </FormControl>
                                <FormControl fullWidth required>
                                    <Label label={"Ville de naissance"} />
                                    <TextField onChange={(event: any) => setStepData("ville_naissance", event.target.value)} variant="outlined" value={data["ville_naissance"]} />
                                </FormControl>
                            </Stack>
                            <Stack direction={'row'} gap={"10px"} width={"100%"} alignItems={"end"} pb={5}>
                                <FormControl fullWidth required>
                                    <Label label={"Pays de naissance"} />
                                    <Select
                                        labelId="demo-simple-select-label"
                                        onChange={(event: any) => setStepData("pays_naissance", event.target.value)}
                                        input={<OutlinedInput value={data["pays_naissance"]} />}
                                    >
                                        {
                                            lstPaysNaissance?.map((pn: any, index: number) => (
                                                <MenuItem value={pn} key={index}>{pn}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth required>
                                    <Label label={"Nationalité"} />
                                    <Select
                                        labelId="demo-simple-select-label"
                                        onChange={(event: any) => setStepData("nationalite", event.target.value)}
                                        input={<OutlinedInput value={data["nationalite"]} />}
                                    >
                                        {
                                            lstNationalite?.map((n: any, index: number) => (
                                                <MenuItem value={n} key={index}>{n}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>
                        </>
                        <>
                            <h3 className='century-bold m-0'>Vos informations</h3>
                            <Stack direction={'row'} gap={"10px"} width={"100%"} alignItems={"end"}>
                                <FormControl fullWidth required>
                                    <Label label={"Nom"} />
                                    <TextField onChange={(event: any) => setStepData("nom", event.target.value)} variant="outlined" value={data["nom"]} />
                                </FormControl>
                                <FormControl fullWidth required>
                                    <Label label={"Prenom"} />
                                    <TextField onChange={(event: any) => setStepData("prenom", event.target.value)} variant="outlined" value={data["prenom"]} />
                                </FormControl>
                            </Stack>
                            <Stack direction={'row'} gap={"10px"} pb={5} width={"100%"}>
                                <FormControl fullWidth required>
                                    <Label label={"Date de naissance"} />
                                    <DatePicker value={dayjs(data["date_naissance"])}
                                        onChange={(value: any) => setStepData("date_naissance", moment(value.toString()).format("YYYY-MM-DD"))}
                                    />
                                </FormControl>
                                <FormControl fullWidth required>
                                    <Label label={"Régime social"} />
                                    <Select
                                        labelId="demo-simple-select-label"
                                        onChange={(event: any) => setStepData("regime_social", event.target.value)}
                                        input={<OutlinedInput value={data["regime_social"]} />}
                                    >
                                        {
                                            lstRegime?.map((rg: any, index: number) => (
                                                <MenuItem value={rg} key={index}>{rg}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>
                        </>
                        <>
                            <h3 className='century-bold m-0'>Vos coordonnées</h3>
                            <FormControl fullWidth required>
                                <Label label={"Adresse"} />
                                <GooglePlacesAutocomplete
                                    apiKey="AIzaSyBA1dplU9rdYSCSnwdrIFg_zuoaqcxW4Rc"
                                    placeholder="Indiquez un lieu"
                                    loader={<p className='century-bold' style={{ fontSize: 14 }}>Chargement des adresses...</p>}
                                    initialValue={data["adresse_actuelle"]["adresse"] ?? ""}
                                    onSelect={handleSelectAdresse}
                                    renderSuggestions={(_active, suggestions, onSelectSuggestion) => (
                                        <div className="suggestions-container">
                                            {suggestions.map((suggestion, index) => (
                                                <MenuItem key={index} onClick={(event) => onSelectSuggestion(suggestion, event)}>
                                                    {suggestion.description}
                                                </MenuItem>
                                            ))}
                                        </div>
                                    )}
                                />
                            </FormControl>
                            <Stack direction={'row'} gap={"10px"} width={"100%"} alignItems={"end"}>
                                <FormControl fullWidth required>
                                    <Label label={"Code postal"} />
                                    <TextField onChange={(event: any) => handleAdresse({ code_postal: event.target.value })} variant="outlined" value={data["adresse_actuelle"]["code_postal"] ?? ""} />
                                </FormControl>
                                <FormControl fullWidth required>
                                    <Label label={"Ville"} />
                                    <TextField onChange={(event: any) => handleAdresse({ ville: event.target.value })} variant="outlined" value={data["adresse_actuelle"]["ville"] ?? ""} />
                                </FormControl>
                            </Stack>
                            <Stack direction={'row'} gap={"10px"} pb={5} width={"100%"}>
                                <FormControl fullWidth required>
                                    <Label label={"Nº de téléphone"} />
                                    <TextField onChange={(event: any) => setStepData("telephone", event.target.value)} variant="outlined" value={data["telephone"]} />
                                </FormControl>
                                <FormControl fullWidth required>
                                    <Label label={"Email"} />
                                    <TextField onChange={(event: any) => setStepData("email", event.target.value)} variant="outlined" value={data["email"]} />
                                </FormControl>
                            </Stack>
                        </>
                    </Stack>
                )
            }
            {
                activeButton === 1 && (
                    <Stack width={"100%"} spacing={2}>
                        <h3 className='century-bold m-0'>Votre conjoint</h3>
                        <RadioGroup
                            sx={{ columnGap: 3, rowGap: 1.5 }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event: any) => handleDataConjoint("gender", event.target.value)}
                        >
                            <FormControlLabel className={"radio-item"} value="Homme" checked={(dataConjoint["gender"] === "Homme")} control={<Radio />} label="Homme" labelPlacement="start" />
                            <FormControlLabel className={"radio-item"} value="Femme" checked={(dataConjoint["gender"] === "Femme")} control={<Radio />} label="Femme" labelPlacement="start" />
                        </RadioGroup>
                        <Stack direction={'row'} gap={"10px"} width={"100%"} alignItems={"end"}>
                            <FormControl fullWidth required>
                                <Label label={"Nom"} />
                                <TextField onChange={(event: any) => handleDataConjoint("nom", event.target.value)} variant="outlined" value={dataConjoint["nom"]} />
                            </FormControl>
                            <FormControl fullWidth required>
                                <Label label={"Prenom"} />
                                <TextField onChange={(event: any) => handleDataConjoint("prenom", event.target.value)} variant="outlined" value={dataConjoint["prenom"]} />
                            </FormControl>
                        </Stack>
                        <Stack direction={'row'} gap={"10px"} width={"100%"} alignItems={"end"}>
                            <FormControl fullWidth required>
                                <Label label={"Nom de naissance"} />
                                <TextField onChange={(event: any) => handleDataConjoint("nom_naissance", event.target.value)} variant="outlined" value={dataConjoint["nom_naissance"]} />
                            </FormControl>
                            <FormControl fullWidth required>
                                <Label label={"Date de naissance"} />
                                <DatePicker value={dayjs(dataConjoint["date_naissance"])}
                                    onChange={(value: any) => handleDataConjoint("date_naissance", moment(value.toString()).format("YYYY-MM-DD"))}
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction={'row'} gap={"10px"} width={"100%"} alignItems={"end"} pb={5}>
                            <FormControl fullWidth required>
                                <Label label={"Régime social"} />
                                <Select
                                    labelId="demo-simple-select-label"
                                    onChange={(event: any) => handleDataConjoint("regime_social", event.target.value)}
                                    input={<OutlinedInput value={dataConjoint["regime_social"]} />}
                                >
                                    {
                                        lstRegime?.map((rg: any, index: number) => (
                                            <MenuItem value={rg} key={index}>{rg}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <FormControl fullWidth required>
                                <Label label={"Nº de sécurité sociale"} />
                                <TextField onChange={(event: any) => handleDataConjoint("securite_sociale", event.target.value)} variant="outlined" placeholder='Numéro de sécurité sociale' value={dataConjoint["securite_sociale"]} />
                            </FormControl>
                        </Stack>
                    </Stack>
                )
            }
            {
                activeButton === 2 && (
                    <Stack width={"100%"} pb={5}>
                        <h3 className='century-bold m-0'>Vos enfants</h3>
                        {
                            dataEnfant.map((_: any, index: number) => (
                                <Stack key={index} spacing={2}>
                                    <p>
                                        Pour votre enfant né le {moment(dataEnfant[index]["date_naissance"]).format("DD/MM/yyyy")}
                                    </p>
                                    <RadioGroup
                                        sx={{ columnGap: 3, rowGap: 1.5 }}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={(event: any) => handleDataEnfant(index, "gender", event.target.value)}
                                    >
                                        <FormControlLabel className={"radio-item"} value="Homme" checked={(dataEnfant[index]["gender"] === "Homme")} control={<Radio />} label="Garçon" labelPlacement="start" />
                                        <FormControlLabel className={"radio-item"} value="Femme" checked={(dataEnfant[index]["gender"] === "Femme")} control={<Radio />} label="Fille" labelPlacement="start" />
                                    </RadioGroup>
                                    <Stack direction={'row'} gap={"10px"} width={"100%"} alignItems={"end"}>
                                        <FormControl fullWidth required>
                                            <Label label={"Nom"} />
                                            <TextField onChange={(event: any) => handleDataEnfant(index, "nom", event.target.value)} variant="outlined" value={dataEnfant[index]["nom"]} />
                                        </FormControl>
                                        <FormControl fullWidth required>
                                            <Label label={"Prenom"} />
                                            <TextField onChange={(event: any) => handleDataEnfant(index, "prenom", event.target.value)} variant="outlined" value={dataEnfant[index]["prenom"]} />
                                        </FormControl>
                                    </Stack>
                                    <Stack direction={'row'} gap={"10px"} width={"100%"} alignItems={"end"}>
                                        <FormControl fullWidth required>
                                            <Label label={"Date de naissance"} />
                                            <DatePicker value={dayjs(dataEnfant[index]["date_naissance"])}
                                                onChange={(value: any) => handleDataEnfant(index, "date_naissance", moment(value.toString()).format("YYYY-MM-DD"))}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth required>
                                            <Label label={"Régime social"} />
                                            <Select
                                                labelId="demo-simple-select-label"
                                                onChange={(event: any) => handleDataEnfant(index, "regime_social", event.target.value)}
                                                input={<OutlinedInput value={dataEnfant[index]["regime_social"]} />}
                                            >
                                                {
                                                    lstRegime?.map((rg: any, i: number) => (
                                                        <MenuItem value={rg} key={i}>{rg}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                    <FormControl fullWidth required>
                                        <Label label={"Nº de sécurité sociale"} />
                                        <TextField onChange={(event: any) => handleDataEnfant(index, "securite_sociale", event.target.value)} variant="outlined" value={dataEnfant[index]["securite_sociale"]} />
                                    </FormControl>
                                </Stack>
                            ))
                        }
                    </Stack>
                )
            }
        </>
    );
};