/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Button, Stack, Box, Avatar } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import { CustomContainer } from '../../components/CustomContainer/CustomContainer';
import { AdminService } from '../../services/services';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './Document.scss';
import logoJulia from "../../assets/logos/Logo_RVB.svg";
import { grey, indigo, orange } from '@mui/material/colors';


function Document() {    
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const nom = queryParams.get('nom');
    const email = queryParams.get('email');
    const prenom = queryParams.get('prenom');
    const [erreur, setErreur] = useState<string|null>("");
    const [success, setSuccess] = useState<boolean>(false);

    useEffect(() => {
        if (queryParams.get('prenom') && queryParams.get('email')) {
            checkCredentials();
        }
    }, []);

    const checkCredentials = async () => {
        /**
         * Vérification credentials
         */      
        const dataCredentials = {
            operateur_id : queryParams.get('operateur_id'),
            member_id : queryParams.get('member_id'),
            devis_id : queryParams.get('devis_id'),
            nom : queryParams.get('nom'),
            prenom : queryParams.get('prenom'),
            email : queryParams.get('email'),
            key : queryParams.get('key'),
            verification : true,
        }

        await AdminService.api_check_member_credentials(dataCredentials).then((data: any) => {
            if (data['erreur']) {
                setErreur(data['erreur']);
            } else {
                console.log(data);
                if(data?.success){
                    setSuccess(true);
                    setErreur('');
                }
            }
        }).catch(() => {
            setErreur('Une erreur s\'est produite');
        });
    };


    return (
        <>
            <CustomContainer>
                <Stack direction={"column"} sx={{ justifyContent: "center", alignItems: "center" }} width={"100%"} height={"100vh"}>
                    <Box onClick={() => { navigate("/") }} component="img" src={logoJulia} alt="logo Julia" width={120}
                        sx={{ mr: 1, cursor: "pointer" }}
                    />
                    <h2>Julia assurance</h2>
                    <p style={{marginTop:0}}>Notre solution de vente et de gestion pour vos projets santé,emprunteur et prévoyances</p>
                    <Stack style={{marginTop:'3rem'}} justifyContent={"end"} alignItems={"start"} display={{ md: "flex" }}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <Avatar sx={{ bgcolor: indigo[700] }} style={{textTransform:'uppercase',fontWeight:'bold'}}>{prenom?.split("")[0]}{nom?.split("")[0]}</Avatar>
                            <Stack display={{ md: "flex" }}>
                                <p className='p-bold' style={{textTransform:'capitalize',margin:0,padding:0,color:grey[600]}}>{prenom} {nom}</p>
                                <p className='p-bold' style={{margin:0,padding:0,fontSize:14,color:grey[500]}}>{email}</p>
                            </Stack>
                        </Stack>
                        <Stack marginTop={3} textAlign={'center'} width={'100%'}>
                            {
                                (erreur==='' && success===false) && <Button style={{backgroundColor:grey[500],textTransform:'none',cursor:'default'}} variant="contained" size='large'>Vérification en cours...</Button>
                            }
                            {
                                (erreur!=='' && success===false) && <Button style={{backgroundColor:orange[800],textTransform:'none',cursor:'default'}} variant="contained" size='large'>{erreur}</Button>
                            }
                            {
                                (erreur==='' && success===true) && <Button onClick={() => { navigate(`/document-upload?${queryParams.toString()}`) }} style={{backgroundColor:indigo[700],textTransform:'none'}} variant="contained" endIcon={<ArrowForwardIcon/>} size='large'>Accéder à mon espace</Button>
                            }
                        </Stack>
                    </Stack>
                </Stack>
            </CustomContainer>
        </>
    )
}

export default Document; 