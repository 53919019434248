// CONSTANTS FOR PARCOURS SANTE CLIENT
export const CHANGE_STEP = 'CHANGE_STEP';
export const CHANGE_STEP_SHOWED = 'CHANGE_STEP_SHOWED';
export const CHANGE_CURRENT_ERROR_MESSAGE = 'CHANGE_CURRENT_ERROR_MESSAGE';
export const CHANGE_INIT_DATA = 'CHANGE_INIT_DATA';
export const CHANGE_INFO = 'CHANGE_INFO';
export const CHANGE_STEPS_DATA = 'CHANGE_STEPS_DATA';
export const CHANGE_BUTTON_STATE = 'CHANGE_BUTTON_STATE';
export const CHANGE_LOADING_STATE = 'CHANGE_LOADING_STATE';
export const CHANGE_PANIER = 'CHANGE_PANIER';
export const CHANGE_PANIER_GLOBAL = 'CHANGE_PANIER_GLOBAL';
export const CHANGE_MEMBER = 'CHANGE_MEMBER';
export const CHANGE_SHOW_PANIER = 'CHANGE_SHOW_PANIER';
export const CHANGE_CAN_BE_SIGNED = 'CHANGE_CAN_BE_SIGNED';
export const CHANGE_PARCOURS_TYPE = 'CHANGE_PARCOURS_TYPE';
// CONSTANTS FOR PARCOURS EMPRUNTEUR CLIENT
export const CHANGE_STEP_EMPRUNTEUR = 'CHANGE_STEP_EMPRUNTEUR';
export const CHANGE_CURRENT_SUBSTEP = 'CHANGE_CURRENT_SUBSTEP';
export const CHANGE_STEPS_DATA_EMPRUNTEUR = 'CHANGE_STEPS_DATA_EMPRUNTEUR';
export const CHANGE_INIT_DATA_EMPRUNTEUR = 'CHANGE_INIT_DATA_EMPRUNTEUR';