const colors = {
    blue: "#2f4c87",
    blue1: "#2d5699",
    blue2: "#6691ca",
    blue3: "#a1b7de",
    pink: "#cc287b",
    teal: "#149db1",
    green: "#bbd148",
    dark: "#4a4a4a",
    dark1: "#00020f",
    dark2: "5d5d5d",
    green_santiane: "#6FB632",
    orange_santiane: "#F39200"
};

export default colors;