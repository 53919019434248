import {
    CHANGE_STEP,
    CHANGE_STEP_SHOWED,
    CHANGE_CURRENT_ERROR_MESSAGE,
    CHANGE_INIT_DATA,
    CHANGE_INFO,
    CHANGE_STEPS_DATA,
    CHANGE_BUTTON_STATE,
    CHANGE_LOADING_STATE,
    CHANGE_PANIER,
    CHANGE_SHOW_PANIER,
    CHANGE_PANIER_GLOBAL,
    CHANGE_MEMBER,
    CHANGE_CAN_BE_SIGNED,
    CHANGE_STEP_EMPRUNTEUR,
    CHANGE_CURRENT_SUBSTEP,
    CHANGE_STEPS_DATA_EMPRUNTEUR,
    CHANGE_INIT_DATA_EMPRUNTEUR,
    CHANGE_PARCOURS_TYPE
} from './constants';


// ACTIONS FOR PARCOURS SANTE CLIENT

export function changeStep(step: number) {
    return {
        type: CHANGE_STEP,
        payload: step
    }
};

export function changeStepShowed(stepShowed: Array<Object>) {
    return {
        type: CHANGE_STEP_SHOWED,
        payload: stepShowed
    }
};

export function changeCurrentErrorMessage(error: string | null) {
    return {
        type: CHANGE_CURRENT_ERROR_MESSAGE,
        payload: error
    }
};

export function changeInitData(data: any) {
    return {
        type: CHANGE_INIT_DATA,
        payload: data
    }
};

export function changeInfo(info: boolean) {
    return {
        type: CHANGE_INFO,
        payload: info
    }
};

export function changeStepsData(stepsData: any) {
    return {
        type: CHANGE_STEPS_DATA,
        payload: stepsData
    }
};

export function changeIsButtonEnabled(enabled: boolean) {
    return {
        type: CHANGE_BUTTON_STATE,
        payload: enabled
    }
};

export function changeLoading(loading: boolean) {
    return {
        type: CHANGE_LOADING_STATE,
        payload: loading
    }
};

export function changePanier(panier: any) {
    return {
        type: CHANGE_PANIER,
        payload: panier
    }
};

export function changePanierGlobal(panierGlobal: any) {
    return {
        type: CHANGE_PANIER_GLOBAL,
        payload: panierGlobal
    }
};

export function changeMember(member: any) {
    return {
        type: CHANGE_MEMBER,
        payload: member
    }
};

export function changeShowPanier(showPanier: boolean) {
    return {
        type: CHANGE_SHOW_PANIER,
        payload: showPanier
    }
};

export function changeCanBeSigned(canBeSigned: boolean) {
    return {
        type: CHANGE_CAN_BE_SIGNED,
        payload: canBeSigned
    }
};

export function changeParcoursType(parcoursType: string) {
    return {
        type: CHANGE_PARCOURS_TYPE,
        payload: parcoursType
    }
};

// ACTIONS FOR PARCOURS EMPRUNTEUR CLIENT

export function changeStepEmprunteur(step: number) {
    return {
        type: CHANGE_STEP_EMPRUNTEUR,
        payload: step
    }
};

export function changeCurrentSubstep(substep: number) {
    return {
        type: CHANGE_CURRENT_SUBSTEP,
        payload: substep
    }
};

export function changeStepsDataEmprunteur(stepsData: any) {
    return {
        type: CHANGE_STEPS_DATA_EMPRUNTEUR,
        payload: stepsData
    }
};

export function changeInitDataEmprunteur(data: any) {
    return {
        type: CHANGE_INIT_DATA_EMPRUNTEUR,
        payload: data
    }
};