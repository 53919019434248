/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box, Stack, Button, AppBar, Modal, Backdrop,
    Fade, Divider, Accordion, AccordionDetails, styled
} from '@mui/material';
import logoJulia from "../../assets/logos/Logo_RVB.svg";
import logoSantiane from "../../assets/logos/logo-santiane.svg";
import { CustomContainer } from '../CustomContainer/CustomContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { useCookies } from 'react-cookie';
import { AdminService } from '../../services/services';
import colors from '../../colors/colors';
import './Cookies.scss';


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 2,
    outline: "none !important",
    p: 3,
};

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));


const Cookies = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [cookies, setCookie] = useCookies(['isTermAccepted']);
    const [open, setOpen] = useState<boolean>(false);
    const [showCookies, setShowCookies] = useState<boolean>();

    const [isBranding, setIsBranding] = useState<null | string | boolean>(queryParams.get('branding'));
    const [dataHomepage, setDataHomepage] = useState<any>({});


    useEffect(() => {
        handleLoadCookie();
    }, []);

    useEffect(() => {
        if (isBranding) {
            getHomePage();
        }
    }, [location.pathname]);


    const getHomePage = async () => {
        await AdminService.api_get_home_page(
            `${location.pathname}?branding=${isBranding?.toString().toLowerCase()}`
        ).then((data: any) => {
            if (data['erreur']) {
                setIsBranding(false);
            } else {
                setDataHomepage(data);
            }
        }).catch(() => {
            setIsBranding(false);
        });
    };

    const handleSaveCookie = () => {
        const currentDate = new Date();
        const expirationDate = new Date();
        expirationDate.setDate(currentDate.getFullYear() + 50);
        setCookie(
            'isTermAccepted', 'yes',
            { path: '/', expires: expirationDate, sameSite: 'strict' }
        );
        setShowCookies(false);
        setOpen(false);
    };

    const handleLoadCookie = () => {
        const isTermsAccepted = cookies.isTermAccepted;
        if (!isTermsAccepted) {
            setShowCookies(true);
        }
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <>
            {/* Bottom banner  */}
            {
                showCookies && (
                    <AppBar
                        position="fixed"
                        sx={{
                            top: 'auto', bottom: 0, py: { xs: 1.5, md: 5 },
                            bgcolor: isBranding ? dataHomepage["primary_color"] : colors.blue1
                        }}
                        className={'bandeau-cookies'}
                    >
                        <CustomContainer>
                            <Stack alignItems={"center"} spacing={2}>
                                <p className="m-0 p-white">
                                    {isBranding ? dataHomepage["nom"] : 'Julia'}, en tant que responsable de traitement, utilise des cookies de fonctionnement nécessaires à la navigation sur le site ainsi que des cookies permettant de mesurer le trafic.
                                    &nbsp;
                                    <span className='url-cookies' onClick={() => !isBranding ? navigate("/mentions-legales") : window.open(dataHomepage["cookies_url"])}>
                                        Cliquez ici pour en savoir plus
                                    </span> et
                                    &nbsp;
                                    <span className='url-cookies' onClick={() => handleOpen()}>ici pour gérer les cookies.</span>
                                </p>
                                <Stack alignItems={"end"} width={"100%"}>
                                    <Button
                                        sx={{ color: isBranding ? dataHomepage["primary_color"] : colors.blue1 }}
                                        className={'btn-accept-cookies'}
                                        variant="contained" onClick={() => handleSaveCookie()}>
                                        J'ai compris
                                    </Button>
                                </Stack>
                            </Stack>
                        </CustomContainer>
                    </AppBar>
                )
            }
            {/* Cookies modal  */}
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 1000 } }}
            >
                <Fade in={open}>
                    <Box sx={style} width={{ md: 600, xs: "80%" }}>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} mb={2}>
                            <Box component="img" sx={{ cursor: "pointer" }} src={!queryParams.get("branding") ? logoJulia : logoSantiane} alt="logo julia" width={100} />
                            <RxCross1 className='cursor-pointer' color='#424242' onClick={() => handleClose()} />
                        </Stack>
                        <Divider />
                        <Stack mb={4} maxHeight={{ xs: "50vh", md: "80vh" }} overflow={"scroll"}>
                            <p className='century-bold' style={{ fontSize: 25 }}>Centre de préférences de la confidentialité</p>
                            <small className='m-0'>
                                Lorsque vous consultez un site Web, des données peuvent être stockées dans votre navigateur ou récupérées à partir de celui-ci, généralement sous la forme de cookies.
                                Ces informations peuvent porter sur vous, sur vos préférences ou sur votre appareil et sont principalement utilisées pour s'assurer que le site Web fonctionne correctement.
                                Sur notre site, les informations ne permettent pas de vous identifier directement, mais peuvent vous permettre de bénéficier d'une expérience Web personnalisée.
                                Néanmoins, nous vous donnons la possibilité de ne pas autoriser certains types de cookies. Cliquez sur les différentes catégories pour obtenir plus de détails sur chacune d'entre elles, et modifier les paramètres par défaut.
                            </small>
                            <p className='century-bold cursor-pointer' style={{ textDecoration: "underline" }}>
                                Consulter notre Politique de gestion des données personnelles et cookies
                            </p>
                            <p className='century-bold' style={{ fontSize: 25 }}>Gérer vos préférences</p>
                            <Accordion sx={{ boxShadow: "none !important", border: "1px solid #eeeeee" }}>
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} width={"100%"} py={1} ml={0.5}>
                                        <p className='m-0 century-bold'>Cookie de fonctionnement</p>
                                        <p className='m-0 century-bold' style={{ color: "#5b9e20", fontSize: 16 }}>Toujours actif</p>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails sx={{ backgroundColor: "rgba(0, 0, 0, .03)" }}>
                                    <small className='m-0'>
                                        Ce sont les cookies ou technologies similaires permettant au site Julia de fonctionner.
                                        Ils servent notamment à accéder à nos différents services, à assurer la sécurité de notre site, à adapter la présentation du site aux préférences d’affichage de votre terminal, etc.
                                        Ces cookies ne peuvent pas être désactivés.
                                    </small>
                                </AccordionDetails>
                            </Accordion>
                        </Stack>
                        <Stack alignItems={"center"} width={"100%"}>
                            <Button
                                sx={{
                                    backgroundColor: isBranding
                                        ? dataHomepage["primary_color"]
                                        : colors.blue1,
                                    '&:hover': {
                                        backgroundColor: isBranding
                                            ? dataHomepage["primary_color"]
                                            : colors.blue2
                                    }
                                }}
                                className={`${!isBranding ? `btn-suivant` : `btn-suivant-branding`}`}
                                variant="contained" onClick={() => handleSaveCookie()}>
                                Sauvegarder les préférences
                            </Button>
                        </Stack>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};


export default Cookies;